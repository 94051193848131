@import '../../../../style/_mixins.scss';
@import '../../../../style/_variables.scss';

.nav--dropdown {
  .nav-item {
    display: flex;
    align-items: center;
    position: static;

    button {
      font-size: $font-size-base;
      color: $color-white;
      text-transform: uppercase;
      font-weight: $font-weight-medium;
      background: transparent !important;
      border: none;
    }

    &.dropdown {
      ul {
        background: $color-blue-light;
        width: 100%;
        display: none;
        left: 0;
        right: 0;
        margin: 0 auto;
        max-width: 100%;
        top: 101px;
        border-radius: 0;
        border: 0;
        text-align: center;
        justify-content: flex-start;

        &.show {
          z-index: 999;
          display: flex;
          height: 70px !important;
          // height: 0px !important;

          align-items: center;
          justify-content: center;

          li {
            padding: 0 15px;

            .dropwdown--active {
              color: $color-orange;
            }

            button {
              font-family: Brandon Grotesque Bold !important;

              &:hover {
                color: $color-orange;
                border-bottom: 0px solid $color-orange;
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .nav--dropdown .nav-item.dropdown ul.show {
    height: 0px !important;
  }

  header nav .navbar-collapse {
    top: 88px !important;
  }
}
