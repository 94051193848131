@import '../../style/_variables.scss';
@import '../../style/_mixins.scss';
@import '../../style/_breakpoints.scss';

.view-load-more {
  display: flex;
  height: 48px;
  padding: 8px 26px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border: none;
  background: $color-orange;
  // font-family: Brandon Grotesque bold !important;
  font-family: 'Brandon Grotesque black';
  text-transform: uppercase;
  color: #222;
}
