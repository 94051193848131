@import './_variables.scss';
@import './_mixins.scss';
@import './breakpoints.scss';

@font-face {
  font-family: Brandon Grotesque;
  src: url(Brandon_reg.otf);
}

@font-face {
  font-family: Brandon Grotesque bold;
  src: url(Brandon_bld.otf);
}

@font-face {
  font-family: Brandon Grotesque medium;
  src: url(Brandon_med.otf);
}

@font-face {
  font-family: Brandon Grotesque thin;
  src: url(Brandon_thin.otf);
}

@font-face {
  font-family: Brandon Grotesque light;
  src: url(Brandon_light.otf);
}
@font-face {
  font-family: 'Brandon Grotesque black';
  src: url(Brandon_black.otf);
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

img,
button,
p,
a,
li,
h1,
h2,
h3,
h4,
h5,
h6 {
  transition: all ease-in-out 0.25s;
}

ul {
  list-style: none;
  padding-left: 0;
}

input {
  &:focus {
    // border: none;
    outline: none;
  }
}

.hero-section {
  height: auto;
  width: 100%;
  .wp-block-cover__inner-container {
    max-width: unset;
    h1 {
      text-align: start;
    }
  }

  img {
    height: 100%;
  }
  .button-wrapper div#sm-request-btn {
    display: inline-flex;
  }
}

.category {
  .hero-section h1 {
    font-family: 'Brandon Grotesque bold';
    font-weight: 450;
    color: $color-white;
  }
}
.category.talent-show {
  .hero-section {
    .wp-block-cover__inner-container {
      h1 {
        text-align: start;
      }
    }
  }
}

.monm-banner {
  .wp-block-cover__inner-container {
    max-width: unset;
  }
}
.Banner {
  .containerCustom {
    @include flex-align;
  }

  .text-block {
    position: absolute;

    h1 {
      color: $color-white;
      margin-left: 4rem;
      @include typography(80px, 500, Brandon Grotesque, 84px);
    }
  }
}

section.featured-article-section {
  h4 {
    font-weight: $font-weight-bold;
    font-size: $font-size-2md;
    font-family: Brandon Grotesque Medium;
  }

  padding: 80px 0 40px;

  .btn-orange {
    color: $color-black;
    background-color: orange;
  }

  .featured-article {
    .article-content {
      h2 {
        @include heading_2;
        margin-bottom: 20px;
      }

      p {
        margin-bottom: 32px;
      }

      .btn {
        margin-left: 0;
      }

      ul {
        list-style: disc;
        padding-left: 20px;
        line-height: 30.8px;
        margin-bottom: 40px;

        li {
          color: $text-color;
          font-size: $font-size-md;
          font-weight: $font-weight-medium-bold;

          a {
            color: $color-blue;
          }
        }
      }
    }
  }
}

//used for changing the order of grid elements
.pos-right {
  order: 1;
}

//used for changing the order of grid elements
.pos-left {
  order: -1;
}

// home-featured-article.css

section.featured-article-section {
  padding: 40px 0 40px;

  &.top-80 {
    padding-top: 80px;
  }
}

section.featured-article-section .featured-article {
  max-width: 1080px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 24px;
  align-items: center;
  margin-bottom: 0px;

  .article-content {
    h3 {
      font-family: brandon grotesque bold;
      font-weight: $font-weight-strong;
    }

    .btn {
      font-family: brandon grotesque bold;
    }
  }
}

section.featured-article-section .featured-article .article-content p {
  margin-bottom: 20px;
  color: $text-color;
  font-size: $font-size-xl;
  font-weight: $font-weight-medium-bold;
  font-family: Brandon Grotesque light;
}

section.featured-article-section .featured-article .article-content .btn {
  font-family: Brandon Grotesque Medium;
  margin-left: 0;
}

section.featured-article-section .featured-article .article-content ul {
  list-style: none;
  padding-left: 20px;
  line-height: 30.8px;
  margin-bottom: 40px;
}

section.featured-article-section .featured-article .article-content ul li {
  color: $text-color;
  font-size: 22px;
}

section.featured-article-section .featured-article .article-content ul li::before {
  content: '\2022';
  font-weight: 100;
  display: inline-block;
  width: 0.5em;
  margin-left: -1em;
}

section.featured-article-section .featured-article .article-content ul li a {
  color: $color-blue;
  text-decoration: none;
}

/*# sourceMappingURL=home-featured-article.css.map */

//   style.scss

.container,
.container-lg,
.container-md,
.container-sm,
.container-xl,
.container-xxl {
  max-width: 1300px;
}

body,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: Brandon Grotesque;
  padding: 0;
}

button:focus:not(:focus-visible) {
  box-shadow: none;
}

p {
  font-size: $font-size-xl;
  line-height: 33.6px;
}

.mb-20 {
  margin-bottom: 20px;
}

a {
  cursor: pointer;
}

.btn-section {
  display: flex;
  justify-content: center;
}

.btn {
  border: 0px;
  border-radius: 0;
  margin: 0 auto;
  font-size: $font-size-base;
  font-weight: $font-weight-medium;
  cursor: pointer;
  display: inline-flex;
  height: 50px;
  padding: 8px 26px;
  justify-content: center;
  align-items: center;
  gap: 10px;
}
.wp-block-group.paragraph {
  .wp-block-buttons {
    padding: 0;
  }
  .btn-orange {
    a {
      padding: 12px 25px;
    }
  }
}

.btn-orange {
  background: $color-orange;
  color: $text-color;
  cursor: pointer;
  a {
    font-size: $font-size-base;
    text-transform: uppercase;
    font-weight: $font-weight-medium-bold;
  }

  &:hover,
  &:focus,
  &:active {
    background: $color-orange-hover;
    color: $text-color;
  }
}

.btn-blue {
  background: $color-blue;
  color: $color-white;
  cursor: pointer;

  &:hover,
  &:focus,
  &:active {
    background: $color-blue-hover;
    color: $color-white;
  }
}

.light-pink-bg {
  background: $light-pink-color;
}

.flex-start {
  //override the inline cms css
  align-items: flex-start !important;
}

.flex-center {
  //override the inline cms css
  align-items: center !important;
}

.flex-end {
  //override the inline cms css
  align-items: flex-end !important;
}

h2 {
  @include heading_2;
  margin-bottom: 20px;
}

h3 {
  margin-bottom: 20px;
  @include typography($font-size-3xl, 500, Brandon Grotesque, 36px);
}

.navbar-brand {
  margin: 0 auto;
}

.paragraph {
  @include container-common-property;
  font-size: $font-size-xl;
  font-family: Brandon Grotesque;
  line-height: 33.6px;
}

hr {
  background: $color-orange;
  height: 2px;
  border: 0;
  opacity: 1;
}

.text-center {
  text-align: center;
}

.mb-20 {
  margin-bottom: 20px;
}

.article-heading {
  @include heading_2;
}

h6 {
  @include h6;
}

.generic-text {
  max-width: 1080px;
  margin: 0 auto;

  a {
    color: $text-color;
    text-decoration: none;
  }

  h2 {
    margin-bottom: 20px;
  }
}

.m-show {
  display: none;
}

.left-button {
  margin-left: 0;
  margin-top: 20px;
}

.generic-text {
  padding: 80px 0px 40px;
}

.table-responsive {
  padding: 10px 0px;
  max-width: 1080px;
  margin: 0 auto;

  table {
    border: 1px solid #ddd;

    & > :not(caption) > * > * {
      border: 1px solid #ddd;
      padding: 10px 20px;
    }

    tr {
      &:nth-child(even) td {
        background: $light-pink-color;
      }
    }
  }
}

.note {
  margin-top: 20px;

  p {
    font-size: $font-size-xs;
    line-height: 18px;
  }
}

.email {
  color: $color-blue;
}

.generic-triptych-section {
  margin: 0 auto;
  max-width: 1080px;
  padding: 40px 0;
  display: flex;
  flex-direction: column;
  gap: 20px;

  .video-section {
    h2 {
      font-family: brandon grotesque bold;
      font-weight: bolder;
    }

    p {
      font-family: brandon grotesque medium;
    }

    .video-details {
      h3 {
        font-family: brandon grotesque bold;
        font-weight: bolder;
      }
    }
  }

  h2 {
    padding: 40px 0;
  }

  .generic-triptych {
    display: grid;
    grid-template-columns: repeat(3, 33.3%);
    gap: 24px;
    align-items: flex-start;

    .video-details {
      max-width: 344px;
      text-align: center;
      display: grid;
      gap: 20px;

      img {
        width: 100%;
      }

      h3 {
        margin-bottom: 0;
      }
    }
  }
}

.all-post-section {
  width: 1080px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  height: 80px;
  align-items: center;

  .all-post {
    display: flex;
    align-items: center;
    gap: 20px;
  }

  .btn-orange {
    margin-right: 0;
  }
}

.btn-outline {
  border: 1px solid #ddd;
  padding: 8px 20px;
  border-radius: 30px;
}

button:focus:not(:focus-visible) {
  box-shadow: none;
}

p {
  font-size: $font-size-xl;
  line-height: 33.6px;
}

.mb-20 {
  margin-bottom: 20px;
}

a {
  cursor: pointer;
}

.btn {
  border: 0px;
  border-radius: 0;
  margin: 0 auto;
  font-size: $font-size-base;
  font-weight: $font-weight-medium;
  cursor: pointer;
  display: inline-flex;
  height: 50px;
  padding: 8px 26px;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.btn-orange {
  background: $color-orange;
  color: $text-color;
  cursor: pointer;

  &:hover,
  &:focus,
  &:active {
    background: $color-orange-hover;
    color: $text-color;
  }
}

.btn-blue {
  background: $color-blue;
  color: $color-white;
  cursor: pointer;

  &:hover,
  &:focus,
  &:active {
    background: $color-blue-hover;
  }
}

.btn {
  min-width: 126px;
  height: 48px;
  border: 0px;
  border-radius: 0;
  @include flex-align;
  margin: 0 auto;
  font-size: $font-size-base;
  font-weight: $font-weight-medium;

  &.btn-orange {
    @include flex-align;
    background: $color-orange;
  }
}

.btn {
  border: 0px;
  border-radius: 0;
  margin: 0 auto;
  font-size: $font-size-base;
  font-weight: $font-weight-medium;
  cursor: pointer;
  display: inline-flex;
  height: 50px;
  padding: 8px 26px;
  justify-content: center;
  align-items: center;
  gap: 10px;

  &.btn-orange {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    background: $color-orange;
    border-radius: 0;
  }
}

.button-row {
  @include flex-align;
  gap: 20px;
  margin: 0px auto 0 !important;
  padding-bottom: 0 !important;
  min-height: 90px;
}

h2 {
  @include heading_2;
  margin-bottom: 20px;
}

h3 {
  margin-bottom: 20px;
  @include typography($font-size-3xl, 500, Brandon Grotesque, 36px);
}

.navbar-brand {
  margin: 0 auto;
}

.paragraph {
  @include container-common-property;
  font-size: $font-size-xl;
  font-family: Brandon Grotesque;
  line-height: 33.6px;
}

hr {
  background: $color-orange;
  height: 2px;
  border: 0;
  opacity: 1;
}

.text-center {
  text-align: center !important;
}

.mb-20 {
  margin-bottom: 20px;
}

.article-heading {
  @include heading_2;
}

h6 {
  @include h6;
}

.generic-text {
  max-width: 1080px;
  margin: 0 auto;

  a {
    color: $text-color;
    text-decoration: none;
  }

  h3 {
    font-weight: $font-weight-bold;
  }
}

.m-show {
  display: none;
}

.left-button {
  margin-left: 0;
  margin-top: 20px;
}

.generic-text {
  padding: 80px 0px 40px;
}

.table-responsive {
  padding: 10px 0px;
  max-width: 1080px;
  margin: 0 auto;

  table {
    border: 1px solid #ddd;

    & > :not(caption) > * > * {
      border: 1px solid #ddd;
      padding: 10px 20px;
    }

    tr {
      &:nth-child(even) td {
        background: $light-pink-color;
      }
    }
  }
}

.note {
  margin-top: 20px;

  p {
    font-size: $font-size-xs;
    line-height: 18px;
  }
}

.email {
  color: $color-blue !important;
}

.generic-triptych-section {
  margin: 0 auto;
  max-width: 1080px;
  padding: 0px 0;
  display: flex;
  flex-direction: column;
  gap: 20px;

  h2 {
    padding: 40px 0;
  }

  .generic-triptych {
    display: grid;
    grid-template-columns: repeat(3, 33.3%);
    gap: 24px;
    align-items: flex-start;

    .video-details {
      max-width: 344px;
      text-align: center;
      display: grid;
      gap: 20px;

      img {
        width: 100%;
      }

      h3 {
        margin-bottom: 0;
      }
    }
  }
}

.all-post-section {
  width: 1080px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  height: 80px;
  align-items: center;

  .all-post {
    display: flex;
    align-items: center;
    gap: 20px;
  }

  .btn-orange {
    margin-right: 0;
  }
}

.btn-outline {
  border: 1px solid #ddd;
  padding: 8px 20px;
  border-radius: 30px;
}

.home {
  .carousel-caption {
    h6 {
      //override the cms inline css
      color: $light-pink-color !important;
      font-weight: bolder !important;
      font-family: Brandon Grotesque Medium;
    }

    h1 {
      //override the cms inline css
      font-family: Brandon Grotesque Bold;
      font-weight: bolder !important;
      margin-left: 0 !important;
      margin-top: 10rem;
    }
  }

  .carousel-indicators {
    gap: 0.5rem;
    bottom: 180px;

    button.carousel-dot {
      color: $color-dot;
    }
  }
}

.landingPage {
  .carousel-caption {
    h6 {
      color: $light-pink-color;
      font-weight: bolder;
      font-family: Brandon Grotesque Medium;
    }

    h1 {
      font-family: Brandon Grotesque Bold;
      font-weight: bolder;
      margin-left: 0;
    }
  }

  .carousel-indicators {
    button.carousel-dot {
      color: $color-dot;
    }
  }
}

section.landingPage.hero-section .carousel-indicators {
  right: auto;
  bottom: 5px;
}

.hero-textcenter-section {
  max-width: 1080px;
  margin: 0 auto;
  padding: 80px 0 40px;
}

//adding separation between sections
.hero-textcenter-section .hero-textcenter {
  margin-bottom: 40px;

  h3 {
    font-family: Brandon Grotesque Bold;
    font-weight: bolder;
  }

  p {
    font-family: Brandon Grotesque Medium;
  }
}

.generic-left-section {
  padding: 40px 0;

  .generic-content {
    max-width: 1080px;
    margin: 0 auto;

    h3 {
      font-family: brandon grotesque bold !important;
    }

    p {
      font-family: brandon grotesque medium !important;
    }
  }
}

.carousel-caption {
  h1 {
    margin-bottom: 20px;
    @include typography(80px, 500, Brandon Grotesque, 84px);
  }

  h6 {
    @include typography($font-size-lg, 500, Brandon Grotesque, 100%);
    letter-spacing: 2px;
    text-transform: uppercase;
    text-align: left;
    color: $color-white !important;
  }

  p {
    max-width: 780px;
  }
}

section.home.hero-section {
  .carousel-indicators {
    bottom: 22% !important;
  }
}

.carousel-indicators {
  align-items: center;
  bottom: 22%;

  button {
    &.active {
      background: $color-blue !important;
      width: 10px;
      height: 10px;
      border-radius: 30px;
      border: 0;
    }

    &.carousel-dot {
      width: 10px;
      height: 10px;
      border-radius: 30px;
      background: $color-dot;
      border: 0;
      margin-right: 4px;
      margin-left: 4px;
    }
  }
}

h6 {
  @include h6;
}

.mission-section {
  .mission-article {
    text-align: center;
    margin: 40px auto 0px;

    .heading {
      max-width: 940px;
      margin: 0 auto;

      h5 {
        color: $color-orange;
        //override cms inline css
        font-size: $font-size-lg !important;
        font-weight: 500 !important;
        line-height: 100%;
        letter-spacing: 2px;
        text-transform: uppercase;
        margin-bottom: 20px;
      }

      h1 {
        color: $text-color;
        font-size: 60px;
        font-weight: bolder !important;
        line-height: 62px;
        font-family: Brandon Grotesque Medium !important;
      }

      h6 {
        @include h6;
        font-family: Brandon Grotesque Medium !important;
      }
    }
  }

  .card-section {
    .card-group {
      display: flex;
      flex-flow: row wrap;
      gap: 24px;
      justify-content: center;
      @include container-common-property;
      padding-top: 0px;

      .card {
        max-width: 250px;
        border-radius: 0;
        border: 0;

        img {
          border-radius: 0;
        }

        .card-body {
          text-align: center;
          padding: 20px 0px !important;
        }

        p {
          padding: 10px !important;
        }
      }
    }
  }
}

.card {
  p,
  h3 {
    padding: 10px !important;
  }
}

.btn {
  .btn-orange {
    color: $color-black;
    background-color: orange;
  }
}

.generic-duplex-section {
  @include container-common-property;
  @include common-bottom-padding-for-40;

  .generic-duplex {
    h2 {
      font-family: brandon grotesque bold !important;
      margin-left: 0;
      margin-bottom: 40px !important;
    }

    padding: 0;

    .generic-thumbnails {
      margin: 40px 0;

      ul {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 20px;
        padding-left: 0;
      }
    }
  }

  h2 {
    margin-bottom: 40px;
  }
}

.generic-thumbnails {
  h2 {
    margin-left: 2rem;
  }
}

.generic-triptych-section {
  margin: 0px auto 40px;
  max-width: 1080px;
}

.generic-triptych-section .video-section .video-details-section {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 24px;
  align-items: flex-start;
}

.generic-triptych-section .video-section .video-details-section .video-details {
  max-width: 344px;
  text-align: center;
  display: grid;
  gap: 20px;
}

/*# sourceMappingURL=mentorship.css.map */

section.generic-wide-section {
  margin: 40px auto;
  max-width: 1080px;
}

section.generic-wide-section .generic-wide-thumbs {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 24px;
  margin-top: 40px;
}

/*# sourceMappingURL=generic-wide-image.css.map */

.carousel-section {
  @include container-common-property;
  padding-bottom: 0;

  .carousel {
    p {
      margin-bottom: 40px;
    }
  }

  .item {
    text-align: center;

    .card {
      display: grid;
      gap: 20px;
      border: none;

      h3 {
        margin-bottom: 0;
      }
    }
  }

  .owl-carousel {
    position: relative;

    .owl-nav.disabled {
      display: flex;
      justify-content: space-between;
      position: absolute;
      width: 100%;
      top: 43%;
      z-index: -1;

      button {
        &.owl-next {
          right: -40px !important;
          position: relative;

          span {
            font-size: 0px;
            position: relative;

            &::after {
              content: '';
              position: absolute;
              left: 0;
              top: 0;
              background: url(https://drive.google.com/uc?id=1j2G0k2oe-sZcnzMRVEPEDj_bE8kcgwLH)
                no-repeat;
              width: 20px;
              height: 40px;
              z-index: 99;
              background-size: contain;
            }
          }
        }

        &.owl-prev {
          left: -40px !important;
          position: relative;

          span {
            font-size: 0px;
            position: relative;

            &::before {
              content: '';
              position: absolute;
              left: 0;
              top: 0;
              background: url(https://drive.google.com/uc?id=1Ty9BXbAJ3lLgqeCwwMivKKBrhVViSR_A)
                no-repeat;
              width: 20px;
              height: 40px;
              z-index: 99;
              background-size: contain;
            }
          }
        }
      }
    }

    .owl-dots.disabled {
      display: block !important;
      width: 100%;
      margin: 50px auto 0;
      text-align: center;

      button.owl-dot {
        span {
          display: none;
        }

        width: 10px;
        height: 10px;
        background: $color-dot !important;
        border-radius: 30px;
        margin: 0 6px;

        &.active {
          background: $color-blue !important;
        }
      }
    }
  }
}

.generic-duplex-section {
  display: grid;
  grid-template-columns: 1fr;
  max-width: 1080px;
  margin: 0 auto;

  .testimonials-section {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;

    h2 {
      grid-column: 1/-1;
      font-family: brandon grotesque bold !important;
      font-size: $font-size-4md;
      font-weight: $font-weight-strong;
    }

    .testimonial {
      display: grid;
      gap: 20px;
      max-width: 530px;
      grid-template-columns: 1fr 1fr;

      h3 {
        font-family: brandon grotesque bold !important;
      }

      p {
        margin-top: 30px;
      }
    }
  }
}

/*# sourceMappingURL=talent-show.css.map */

.wordcloud-fpo-section {
  max-width: 1080px;
  margin: 0 auto;
  padding: 80px 0 80px;
}

.wordcloud-fpo-section .word-cloud {
  display: flex;
  justify-content: center;
  padding: 0px 0;
  flex-direction: column;
}

.wordcloud-fpo-section h2 {
  margin-bottom: 40px !important;
  border-bottom: 2px solid $color-orange;
  padding-bottom: 40px !important;
  font-size: $font-size-5xl;
  font-weight: $font-weight-strong;
  line-height: 46px;
}

button:focus:not(:focus-visible) {
  box-shadow: none;
}

.btn {
  border: 0px;
  border-radius: 0;
  margin: 0 auto;
  font-size: $font-size-base;
  font-weight: $font-weight-medium;
  cursor: pointer;
  display: inline-flex;
  height: 50px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  font-family: Brandon Grotesque bold;
}

.btn-orange {
  background: $color-orange;
  color: $text-color;
  cursor: pointer;
}

.btn-orange:hover,
.btn-orange:focus {
  background: $color-orange-hover;
  color: $text-color;
}

.btn-blue {
  background: $color-blue;
  color: $color-white !important;
  cursor: pointer;
}

.btn-blue:hover,
.btn-blue:focus {
  background: $color-blue-hover;
}

.light-pink-bg {
  background: $light-pink-color;
  padding: 1rem 0rem;
  .container {
    max-width: 720px;
  }
}

.advisory-board-section {
  .container {
    max-width: 1140px;
  }
}

h2 {
  color: $text-color;
  font-size: $font-size-5xl;
  font-weight: $font-weight-medium;
  line-height: 46px;
  margin-bottom: 20px;
}

h3 {
  margin-bottom: 20px;
  font-family: Brandon Grotesque bold;
  line-height: 36px;
}

.navbar-brand {
  margin: 0 auto;
}

hr {
  margin: 0 !important;
}

.paragraph {
  max-width: 1080px;
  margin: 0 auto;
  width: 90%;
  padding: 80px 0 0px !important;
  font-size: $font-size-xl !important;
  font-family: Brandon Grotesque Medium;
  line-height: 33.6px !important;

  h3 {
    font-family: Brandon Grotesque Bold !important;
    margin-top: 2rem;
  }

  p {
    font-family: Brandon Grotesque Medium;
    margin-bottom: 40px;
  }
}

hr {
  background: $color-orange !important;
  height: 2px !important;
  border: 0 !important;
  opacity: 1 !important;
}

.text-center {
  text-align: center !important;
}

.mb-20 {
  margin-bottom: 20px;
}

.article-heading {
  color: $text-color;
  font-size: $font-size-5xl;
  font-weight: $font-weight-strong;
  line-height: 46px;
}

h6 {
  color: $color-orange;
  font-style: normal;
  font-weight: $font-weight-strong;
  line-height: 100%;
  letter-spacing: 2px;
  text-transform: uppercase;
  margin-bottom: 20px ;
}

.generic-text {
  max-width: 1080px;
  margin: 0 auto;

  h1 {
    font-family: brandon grotesque bold !important;
    font-size: $font-size-5xl;
  }

  p {
    font-family: brandon grotesque medium !important;
  }
}

.m-show {
  display: none;
}

.left-button {
  margin-left: 0;
  margin-top: 20px;
}

.generic-text {
  @include common-padding;

  h3 {
    font-family: brandon grotesque bold !important;
    padding-top: 2rem;
  }

  a {
    color: $text-color;
    text-decoration: none;
  }
}

.table-responsive {
  padding: 10px 0px;
  max-width: 1080px;
  margin: 0 auto;
}

.table-responsive table {
  border: 1px solid #ddd;
}

.table-responsive table > :not(caption) > * > * {
  border: 1px solid #ddd;
  padding: 10px 20px;
}

.table-responsive table tr:nth-child(even) td {
  background: $light-pink-color;
}

.note {
  margin-top: 20px;
}

.note p {
  font-size: $font-size-xs;
  line-height: 18px;
}

.form-check .form-check-input {
  float: left;
  margin-left: -1.5em;
  background: none;
  border: 1px solid #222;
}

.row {
  --bs-gutter-x: 0rem !important;
}

.who-we-are-section {
  margin: 80px 0 40px;
}

//   mission pillars
.mission-pillar-section {
  padding-top: 0;

  .article-pillars {
    h4 {
      margin-bottom: 0;
      font-weight: $font-weight-medium-bold;
      font-family: brandon grotesque bold;
      font-size: $font-size-3xl-md;
    }

    .pillars-card {
      display: flex;
      gap: 16px;
      @include common-bottom-padding-for-40;
      justify-content: center;
      flex-wrap: wrap;

      .pillars {
        background: $color-blue-light;
        color: $color-white;
        width: 252px;
        padding: 40px 20px;
        text-align: center;
      }
    }
  }
}

/*# sourceMappingURL=financials.css.map */

.carousel-control-next,
.carousel-control-prev {
  width: 4% !important;
}

.carousel-caption {
  position: absolute;
  right: 0;
  bottom: 0 !important;
  left: 0 !important;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  color: $color-white;
  text-align: left !important;
  height: 100%;
  display: flex !important;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
  max-width: 470px;
  margin: 0 auto;
  left: 6% !important;
  right: auto !important;
}

.owl-carousel {
  p {
    text-align: center;

    br {
      display: none;
    }
  }
}

.card-title {
  font-family: Brandon Grotesque medium !important;
  font-weight: bold;
  font-size: $font-size-2xl;
}

.card-text {
  font-family: Brandon Grotesque Medium;
}

.card-body {
  .btn {
    font-weight: $font-weight-bold;
  }
}

.carousel {
  font-family: brandon grotesque Medium !important;

  h2 {
    font-family: brandon grotesque bold !important;
    font-weight: $font-weight-strong;
    color: $color-white !important;
    font-size: $font-size-5xl;
  }

  p {
    font-weight: $font-weight-medium;

    br {
      display: none;
    }
  }
}

.card {
  align-items: center;

  h3 {
    font-family: brandon grotesque bold !important;
    font-weight: $font-weight-strong;
    text-align: center;
  }

  h2 {
    font-size: 1.75rem !important;
    line-height: unset !important;
    margin-bottom: 0px !important;
  }

  p {
    text-align: center;
  }

  .btn {
    font-family: brandon grotesque bold !important;
  }

  .card-section {
    h5 {
      &.card-title {
        @include typography($font-size-3xl, 500, Brandon Grotesque, 36px);
      }
    }
  }
}

.pillars-card.mission-piller {
  gap: 24px;
  flex-wrap: unset;
}

.info-card {
  &:nth-last-child(-n + 3) {
    margin: 0 auto !important;
  }
}

.carousel-section {
  .owl-carousel {
    .owl-item {
      h3 {
        text-align: center;
        margin-bottom: 0 !important;
        padding-bottom: 0 !important;
      }
    }

    .card {
      border: none;
    }
  }
}

h2 {
  font-family: Brandon Grotesque bold;
}

section {
  &.generic-duplex-section {
    &.top-0 {
      .generic-thumbnails {
        h2 {
          margin-left: 0;
        }

        ul {
          padding-left: 0;
        }
      }
    }
  }
}

.common-top-padding-for-40 {
  @include common-top-padding-for-40;
}

.common-margin-for-40 {
  @include common-margin-for-40;
}

.carousel {
  padding: 0 !important;
}

.large-12.columns {
  padding: 0 !important;
}

.video-section {
  padding: 0 !important;
}

section.generic-left-section {
  &.light-pink-bg {
    &.text-center {
      padding-bottom: 40px !important;
    }
  }
}

.top-0 {
  padding-top: 0px !important;
}

.top-20 {
  padding-top: 20px !important;
}

.top-40 {
  @include common-bottom-padding-for-40;
}

.top-30 {
  padding-top: 30px !important;
}

.top-40 {
  @include common-bottom-padding-for-40;
}

.top-60 {
  padding-top: 60px !important;
}

.top-80 {
  padding-top: 80px !important;
}

.bottom-20 {
  padding-bottom: 20px !important;
}

.bottom-40 {
  padding-bottom: 40px !important;
}

.bottom-30 {
  padding-bottom: 30px !important;
}

.bottom-40 {
  padding-bottom: 40px !important;
}

.bottom-60 {
  padding-bottom: 60px !important;
}

.bottom-80 {
  padding-bottom: 80px !important;
}

.welcome-text {
  max-width: 1080px !important;
  margin: 0 auto !important;
}

.img-fluid {
  width: 100%;
  max-width: 100%;
}

.category-container {
  max-width: 1080px;
  margin: 0 auto;
}

.subcategory {
  .light-pink-bg {
    padding-bottom: 40px !important;
  }
}

.advisory-list-section {
  margin-top: 2rem !important;
  grid-template-columns: 1fr 1fr;
  display: grid;
  gap: 20px;
}

.advisory-list {
  h3 {
    font-size: xx-large;
  }

  p {
    font-size: 21px;
  }
}

.financials-section {
  margin: 2rem;

  ul {
    list-style-type: disc;
    margin-left: 20px;
    padding-left: 0;
  }

  li {
    margin-bottom: 5px;
  }

  a {
    color: $color-black;
    font-weight: $font-weight-bold;
    font-size: $font-size-lg;
  }
}

section.mission-pillar-section {
  &.get-involved-section {
    .container {
      max-width: 960px !important;
    }
  }
}

.marathon-images {
  display: flex;
  justify-content: center;

  .img-fluid {
    width: 530px;
    margin-right: 1rem;
  }
}

.featured-article-section {
  .container {
    max-width: 1320px;
  }
}

.generic-text {
  p {
    br {
      display: none;
    }
  }
}

.paragraph {
  p {
    br {
      display: none;
    }
  }
}

.article-content {
  p {
    br {
      display: none;
    }
  }
}

a {
  br {
    display: none;
  }
}

p:empty {
  display: none;
}

.owl-item.active {
  min-height: auto !important;
}

.testimonials-section .generic-text-section a.btn.btn-orange br {
  display: none;
}

a.btn.btn-orange br {
  display: none;
}

p br {
  display: none;
}

.story-wrapper .story {
  justify-content: flex-start;
}

.blog-wrapper .blog-content-wrapper .blog-content__item .story {
  align-items: center !important;
  justify-content: flex-start !important;
}

.home .carousel-caption h1 {
  max-width: 60%;
}

.mission-pillar-section .article-pillars h4 {
  max-width: 1080px;
  margin: 0 auto;
  padding: 0 !important;
}

section.home.hero-section .carousel-item .carousel-caption h1 {
  max-width: 55%;
  margin-top: 18% !important;
}

.nav-link:focus-visible {
  outline: 0;
  box-shadow: none;
}

.left-button {
  margin-top: 0 !important;
}

.owl-carousel .owl-item {
  min-height: auto;
}

.generic-text-section {
  h3 {
    font-size: xx-large !important;
  }
}

.landing-page-images {
  height: auto !important;
  width: 100%;
  display: grid;
  grid-template-columns: 40% 60%;
  background: $color-blue;

  .left-section {
    color: $color-white;
    height: 100%;
    width: 100%;
    margin: auto;

    h2,
    p {
      color: $color-white !important;
    }
    .text-group {
      height: 458px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      padding: 0 50px;
    }
  }

  .right-section {
    width: 100%;
    height: 100%;

    figure,
    img {
      width: 100%;
      height: 100%;
    }
  }
}

section.landingPage.hero-section {
  .carousel-indicators {
    /* right: 0 !important; */
    bottom: 60px;
    margin: 0 auto;
    /* left: 0 !important; */
    max-width: 37% !important;
    width: 100%;

    button.carousel-dot {
      margin-left: 8px;
      margin-right: 8px;
    }
  }

  .carousel-arrow-button {
    max-width: 720px !important;
    width: 562px;
    position: relative;
    height: 576px;

    .carousel-control-next,
    .carousel-control-prev {
      top: auto;
      bottom: 70px;
    }
  }
}

.hero-section-with-two-images {
  display: grid;
  grid-template-columns: 40% 60%;
  width: 100%;
  height: auto;
  background-color: $color-red;

  .wp-block-cover__background,
  .has-background-dim {
    display: none;
  }

  .right-section {
    height: auto;

    figure {
      margin-bottom: 0;

      img {
        width: 100%;
        height: auto;
      }
    }
  }

  .left-section {
    height: auto;

    img {
      width: 100%;
      height: auto;
    }
  }
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

.lazy-load-image-background {
  width: 100%;
}

.loader {
  height: 40vh;
  @include flex-align;
}

.leadership-block {
  flex-basis: 100% !important;
}

.advisroy-row {
  margin: 0 auto !important;
  max-width: 1080px;
  width: 90%;
  display: flex;
  gap: 24px;

  .wp-block-column {
    width: 50%;
    margin-bottom: 40px;
  }

  .advisory-board-member {
    font-weight: bolder !important;
    font-size: $font-size-3xl;
    line-height: 36px;
  }

  p {
    font-size: $font-size-md !important;
    margin: 0;
  }
}

.is-style-wide {
  margin: 0 auto 40px !important;
  max-width: 1080px;
  width: 90%;
}

.bod-advisory-heading {
  margin: 0 auto !important;
  max-width: 1080px;
  width: 90%;
  padding: 80px 0 40px !important;
  font-size: $font-size-5xl !important;
  line-height: 46px !important;
}

.hero-section {
  span.wp-block-cover__background.has-background-dim-10.has-background-dim {
    display: none;
  }

  img {
    height: 100% !important;
    object-fit: fill !important;
  }
}

// board of directors
.board-of-director-section {
  @include container-common-property;

  .generic_quad {
    margin-top: 30px;
    text-align: center;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    align-items: flex-start;
    gap: 24px;

    .board-card {
      max-width: 252px;
      display: grid;
      gap: 20px;
      margin: 0 auto;

      h3 {
        margin-bottom: 0px !important;
      }

      p {
        font-size: $font-size-md;
      }
    }
  }

  .article-heading {
    h2 {
      font-family: brandon grotesque bold !important;
    }
  }
}

.talent-show-section {
  .video-details-section {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 24px;
    align-items: flex-start;

    .video-details {
      p {
        margin: 30px 0 !important;
        margin-top: 10px !important;
      }

      figure {
        img {
          width: 100%;
        }
      }
    }
  }
}

.talent-show-blue-card {
  display: flex;
  justify-content: center;
  flex-direction: column;
  max-width: 1080px;
  margin: 48px auto;
  padding: 0 20px;
  position: relative;

  .dream-notes-block {
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin: 0;

    .content-section {
      width: 100%;
      display: grid;
      grid-template-columns: 1fr;
    }
  }

  .wp-block-column {
    display: flex;
    flex-direction: column;
    padding-right: 20px;
  }

  h2 {
    margin-bottom: 40px !important;
  }
}

// community
.community-section {
  @include container-common-property;

  .article-heading {
    h2 {
      font-family: brandon grotesque bold !important;
      font-size: $font-size-5xl !important;
    }
  }

  .generic_quad {
    margin-top: 30px;
    text-align: center;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    align-items: flex-start;
    gap: 24px;
    margin-bottom: 80px;

    .board-card {
      max-width: 252px;
      display: grid;
      gap: 20px;
      margin: 0 auto;

      h3 {
        margin-bottom: 0px;
        font-family: brandon grotesque bold !important;
        margin-bottom: 0px !important;
      }

      .btn-learn {
        border: none !important;
        background: none;
        font-size: $font-size-lg;
        color: $color-blue;
        text-decoration: none;
      }
    }
  }
}

.paragraph {
  padding: 80px 0 80px !important;
  display: block !important;
}

.btn.btn-orange a {
  // color: $color-black;
  color: $text-color;
  text-decoration: none;
  padding: 12px 25px;
}

.hero-textcenter {
  flex-direction: column;
  gap: 20px;
}

.hero-textcenter-section {
  display: block;
  padding: 40px 0 40px;
}

.hero-textcenter h5 {
  margin-bottom: 0px !important;
  @include typography($font-size-3xl, 500, Brandon Grotesque bold, 36px);
}

.hero-textcenter h6 {
  font-size: $font-size-xl;
  line-height: 33.6px;
  text-transform: none !important;
  color: $color-black !important;
  margin-top: 20px;
  letter-spacing: 0 !important;
}

.pink-section {
  .wp-block-media-text {
    margin: 50px auto !important;
    max-width: 1080px;
    gap: 24px;
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  figure.wp-block-image.alignfull.size-large img {
    width: 100%;
  }

  .is-vertically-aligned-center {
    &.image-text-block {
      align-items: center;
    }
  }
}

// leadership

.leadership-section {
  @include container-common-property;

  .leadership-card {
    display: grid;
    grid-template-columns: repeat(3, 252px);
    justify-content: center;
    text-align: center;
    margin: 0px auto 40px;
    gap: 24px;

    .leadership {
      text-align: center;
      gap: 18px;
      margin-bottom: 40px;
    }
  }
}

.dream-notes-description {
  margin: 3rem auto;
  max-width: 1080px;
}

.hero-section {
  h1 {
    @include typography($font-size-5xl, 450, Brandon Grotesque, 1.4);
    margin-bottom: 1rem;
    max-width: 15ch;
    word-break: break-word;
    margin-left: 65px;

    a {
      color: $color-white;
      text-decoration: none;
      pointer-events: none;
      cursor: not-allowed;
    }
  }

  .wp-block-button {
    @include flex-align;
    background-color: $color-orange;
    width: 189px;
    height: 52px;
    text-align: center;
    border: none;
    margin-left: 50px;
    cursor: pointer;

    a {
      color: $text-color;
      font-family: 'Brandon Grotesque black';
      text-decoration: none;
      font-weight: 500 !important;
      margin: auto;
      align-items: center !important;
      font-size: $font-size-base;
      @include flex-align;
      width: 100%;
      height: 100%;
      text-align: center;
    }
  }
}

.talent-show .wp-block-button:hover {
  background-color: #ED9933;
}

.attendee-form-block {
  .donation-info-section .form .information-form-secton {
    padding: 0px 0 40px;
  }
}

.attendee-form-table {
  table {
    font-family: arial, sans-serif;
    border-collapse: collapse;
    width: 100%;
  }

  td,
  th {
    border: 1px solid $gray-color;
    text-align: center;
    padding: 8px;
  }

  tr:nth-child(odd) {
    background-color: $color-white;
  }

  tr:nth-child(even) {
    background-color: $light-pink-color;
  }
}

.mom-description {
  margin: auto;
  max-width: 1080px;
}

.mom-cover-image {
  text-align: center;
  max-width: 1080px;
  margin: auto;
  padding: 0;

  img {
    width: 100%;
    height: auto;
  }
}

.image-row {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  margin: 40px auto;
  max-width: 1080px;
  gap: 24px;

  .wp-block-column figure {
    margin: 0 !important;
    width: 100% !important;

    img {
      margin: 0 !important;
      padding: 0 !important;
      width: 100%;
    }
  }
}

.testimonial-block {
  background-color: $light-pink-color;
  margin-bottom: 80px;

  .wp-block-media-text {
    max-width: 1080px;
    margin: 0 auto;
    display: flex;
    gap: 24px;
    align-items: center;
    margin-bottom: 0px;
  }

  .wp-block-media-text__media {
    max-width: 100%;
    height: auto;
    margin-top: 2rem;
    margin-bottom: 2rem;
    width: 50%;
  }

  .wp-block-media-text__content {
    margin-bottom: 20px;
    color: $text-color;
    font-size: $font-size-xl;
    font-weight: $font-weight-normal;
    width: 50%;
  }

  .wp-block-media-text__media img {
    max-width: 100%;
  }
}

.testimonals-paragraph {
  max-width: 1080px;
  width: 90%;
  margin: 0 auto;
  text-align: center;
  padding: 40px 0;

  h6 {
    color: $color-black !important;
    text-transform: capitalize !important;
    line-height: 18px !important;
    font-weight: bolder !important;
  }
}

.monm-banner {
  p {
    font-size: 5rem !important;
    font-weight: bold;
    padding-left: 3rem;
  }
}

.hero-textcenter {
  h5 {
    margin-bottom: 20px !important;
  }

  h6 {
    line-height: 140%;
  }
}

.donate__page__para {
  max-width: 1080px;
  margin: 80px auto 80px;
}

p {
  font-size: $font-size-xl;
}

.donation-info-section {
  .form {
    padding: 0px 0 0;

    .information-form-secton {
      padding: 66px 0 40px;

      .note {
        p {
          font-size: $font-size-xs !important;
          font-style: normal;
          font-weight: 325 !important;
          line-height: 18px;
        }
      }
    }

    .amount-section {
      .amount-btn {
        font-family: 'Brandon Grotesque bold';
      }
    }
  }
}

section.donation-info-section {
  br {
    display: none;
  }

  p {
    font-size: $font-size-md !important;
    line-height: normal;
    font-weight: $font-weight-normal-medium;
    line-height: 140%;
  }
}

.ticket-donations-card {
  width: 252px;
  flex-basis: unset;

  figure {
    img {
      width: 100%;
      height: auto;
    }
  }
}

.ticket-donate-btn-section {
  padding: 0 !important;

  .button-row {
    min-height: 90px;

    .btn {
      color: $text-color;
      margin: 0px 10px;
    }
  }
}

.ticket-donations-card-section {
  @include flex-align;
  max-width: 1080px;
  margin: 0 auto;
  gap: 24px;
  text-align: center;

  h3 {
    strong {
      mark {
        color: $color-white !important;
      }
    }
  }
}

.generic-text br {
  display: block !important;
}

.dream-notes-description {
  margin: 80px auto 40px;
}

.dream-notes-block {
  margin: 80px auto;

  .content-section {
    .wp-block-heading {
      font-weight: bolder;
      font-size: $font-size-3xl;
      line-height: 36px;
      font-family: 'Brandon Grotesque bold' !important;
    }

    .wp-block-group {
      p {
        padding-top: 20px;
      }
    }
  }
}

.sponsorship-section {
  padding: 80px 0 50px;

  .sponsortext {
    padding: 0 0 30px 0;
  }
}

.nyc-marathon-section {
  padding: 80px 0 40px;

  .marthontext {
    padding-top: 20px;
  }
}

.marthonpanel {
  margin-top: 0;
  margin-bottom: 80px;
}

header {
  &.wp-block-cover {
    h4 {
      text-align: left;
      max-width: 57%;
    }
  }
}

.donor-advised-section {
  padding: 80px 0 40px;

  br {
    display: block;
  }
}

.donorspanel {
  margin-top: 0;
  margin-bottom: 80px;
}

.what-we-do-section {
  &.our-work-title-and-text {
    max-width: 1080px;
    margin: 0 auto;
    padding: 80px 0 40px;
    gap: 40px;
    align-items: flex-start !important;
    width: 100%;
  }
}

.our-work-sub-group {
  padding-left: 0;
  padding-right: 0;
  max-width: 1080px;
  margin: 0 auto 80px;
  width: 100%;
}

.our-work-sub-title {
  margin-top: 0;
  font-size: $font-size-3xl;
}

ul.our-work-list {
  margin-bottom: 40px;
}

.paragraph {
  max-width: 1080px;
  margin: 0 auto;
  width: 90%;
}

.pink-section {
  .wp-block-media-text {
    margin: 0px auto !important;
  }

  .image-text-block figure {
    margin-bottom: 0;
  }
}

.testimonial-section {
  //padding: 80px 0 40px !important;

  .testimonial {
    text-align: center;
    margin-bottom: 40px;
  }
}

.carousel-section {
  padding: 40px 0 40px;
  padding-bottom: 0;

  .owl-theme .owl-dots .owl-dot.active span,
  .owl-theme .owl-dots .owl-dot:hover span {
    background: $color-blue;
  }
}

.grantsblock {
  h1 {
    margin-top: 80px;
  }

  h2 {
    margin: 40px 0 !important;
  }
}

.word-cloud-section {
  margin: 0 auto;
  max-width: 1080px;
}

.stats-section {
  .wp-block-media-text__content {
    font-family: 'Brandon Grotesque bold';

    br {
      display: block;
    }
  }
}

footer .footer .follow-section figure {
  grid-column: 3/-2;
  text-align: right;
}

.missionhd {
  margin-bottom: 0px !important;
}

.feature-stories-blog-section {
  max-width: 1080px;
  margin: 0 auto;
}

h3.blog-card-title {
  text-align: center;
}

.wp-block-buttons {
  display: flex;
  justify-content: center;

  .blog-card-button {
    width: fit-content;
    width: fit-content;
    background-color: $color-orange;
    padding: 0;
    width: 121px;
    height: 48px;
    @include flex-align;
  }
}

h2.blog-main-title {
  padding-bottom: 40px !important;
}

.blog-paragraph {
  padding: 60px 20px 40px !important;
  max-width: 1080px;
  margin: 0 auto;
}

.carousel-control-next,
.carousel-control-prev {
  width: 8% !important;
}

.carousel-control-next-icon,
.carousel-control-prev-icon {
  width: 3.5rem !important;
  height: 3.5rem !important;
}

.carousel-indicators button.carousel-dot {
  width: 14px;
  height: 14px;
}

.superblockslider__slide_inner {
  width: 100%;
  height: 90%;
  top: 0%;
  left: 0;
}

.superblockslider__button__navigation svg {
  fill: #fff !important;
}

.superblockslider {
  height: 560px !important;
  max-width: 100% !important;

  &__slide_inner {
    .inner-blocks {
      overflow: hidden;

      figure {
        margin-bottom: 0;

        img {
          width: 100%;
        }
      }
    }
  }

  &__buttons {
    bottom: 28%;
    top: auto;
    left: 20% !important;
    right: auto;
  }

  &__navigation {
    top: 40%;
  }
}

.superblockslider__button--active svg,
.superblockslider__button:hover svg {
  fill: $color-blue !important;
}

.superblockslider__button svg {
  fill: $color-dot;
}

.getinvoled__columns.two-x-two {
  display: grid !important;
  justify-content: center !important;
  grid-template-columns: auto auto !important;
  text-align: center;
  margin: 0 auto;
}

.hero-section img {
  height: 100% !important;
}

.carousel-section {
  .owl-carousel {
    h2 {
      font-size: $font-size-xl !important;
      line-height: normal !important;
      text-align: center;
      margin-bottom: 30px !important;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      min-height: 75px;
    }

    .view-load-more {
      display: inline-block;
    }

    .carousel-card {
      text-align: center;
    }

    .owl-nav {
      position: absolute;
      width: 100%;
      top: 35%;

      button {
        &.owl-prev {
          left: -60px;
          position: absolute;
          font-size: 70px;

          &:hover {
            background: none;
            color: $color-black;
          }

          span {
            background: url(../../public/images/caret_large_left.png) no-repeat;
            background-size: contain;
            color: transparent;
          }
        }

        &.owl-next {
          right: -60px;
          position: absolute;
          font-size: 70px;

          &:hover {
            background: none;
            color: $color-black;
          }

          span {
            background: url(../../public/images/caret_large_right.png) no-repeat;
            background-size: contain;
            color: transparent;
          }
        }
      }
    }
  }
}

.owl-theme .owl-dots,
.owl-theme .owl-nav {
  margin-top: 20px;
}

.learningCarousel .carousel-card img {
  width: 100%;
  height: 305px;
}

.show-navigation {}

.hide-navigation {
  .owl-nav {
    display: none;
  }
}

.media-with-left-caption-section {
  background: $light-pink-color;
  padding: 10px 0rem !important;
  margin: 0px auto;

  .media-with-left-caption-images,
  .media-with-right-caption-images {
    width: 100%;
    display: grid;
    grid-template-columns: 50% 50%;
    gap: 24px;
    padding: 10px 0;
    margin: 0px auto;
    max-width: 1080px;
    justify-content: center;

    .caption-image {
      max-width: 528px;
      height: auto;
    }

    .content {
      display: flex;
      flex-direction: column;
      align-items: stretch;
      justify-content: center;
    }

    .wp-block-buttons {
      width: fit-content;

      .btn-orange {
        text-transform: uppercase;
        a {
          text-decoration: none;
          // color: $color-black;
          font-weight: bold;
        }
      }
    }
  }
}

.ticket-donation-banner img.wp-block-cover__image-background {
  height: 100% !important;
}

.view-large {
  a {
    text-transform: uppercase !important;
  }
}

.view-small {
  display: none;
}

.wp-block-cover.alignfull.hero-section {
  height: auto !important;
  min-height: unset !important;
}

.wp-block-cover.alignfull.hero-section .wp-block-cover__image-background {
  position: relative !important;
  height: auto !important;
  margin: 0 !important;
  padding: 0 !important;
  width: 100%;
  object-fit: cover;
}

.wp-block-cover.alignfull.hero-section .wp-block-cover__inner-container {
  position: absolute !important;
  left: 0 !important;
  justify-content: center;
  padding: 0px !important;
}

.wp-block-cover.alignfull.hero-section {
  padding: 0;
}

section#scholarships h5 {
  text-transform: none !important;
}

.video-details {
  .wp-block-group {
    @include flex-align;
    flex-direction: column;
  }

  p.has-text-align-center {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-align: center;
    min-height: 68px;
  }
}

.ticket-donations-card-section {
  h3 {
    line-height: normal !important;
  }
}

.learningCarousel .carousel-card img {
  @include breakpoint-max-custom(600) {
    width: 100%;
    height: auto;
  }
}

.category {
  .hero-section h1 {
    //use important for override cms inline css
    font-family: Brandon Grotesque bold;
    font-weight: 450 !important;
    color: $color-white;
    margin: 0px;

    @include breakpoint-min('xs') {
      font-size: $font-size-base;
      line-height: 18px;
      padding: 8px;
    }

    @include breakpoint-min('sm') {
      font-size: $font-size-lg;
      line-height: 22px;
      padding: 12px;
    }

    @include breakpoint-between(391, 767) {
      font-size: clamp(18px, 5vw, 24px);
      text-align: left;
    }

    @include breakpoint-min('md') {
      font-size: $font-size-3xl;
      line-height: 36px;
      padding: 0px;
      margin-left: 30px;
      max-width: 228px;
      padding-bottom: 0px;
    }

    @include breakpoint-min('lg') {
      max-width: 344px;
      margin-left: 40px;
      padding: 0px;
      padding-bottom: 0px;
    }

    @include breakpoint-min('xl') {
      font-size: $font-size-5xl;
      line-height: 46px;
      width: 410px;
      padding-bottom: 0px;
      padding: 0px;
      margin-left: 50px;
    }
  }
  .wp-block-cover__inner-container {
    .wp-block-buttons {
      @include breakpoint-min('md') {
        margin-top: 12px;
      }
      @include breakpoint-min('lg') {
        margin-top: 16px;
      }
      @include breakpoint-min('xl') {
        margin-top: 30px;
      }
    }
  }
}

.button-wrapper {
  @include breakpoint-max('sm') {
    background-color: $light-pink-color;
    padding-top: 10px;
    @include flex-align;
    padding-bottom: 10px;
    width: 100%;
    height: 55px;
  }
  .wp-block-button-wrapper {
    @include breakpoint-max('sm') {
      @include flex-align;
      background-color: $color-orange;
      padding: 8px 16px;
      text-decoration: none;
    }
    a {
      @include breakpoint-max('sm') {
        text-decoration: none;
        color: $text-color;
        font-weight: $font-weight-medium;
        font-size: $font-size-xs;
        font-family: 'Brandon Grotesque black';
      }
    }
  }
}

@include breakpoint-min('xs') {
  .talent-show .hero-section {
    .wp-block-buttons {
      display: none;
    }
  }
  .talent-show {
    #sm-request-btn{
      display: inline-flex;
    }
  }
  .social-media-content {
      font-size: $font-size-3xl;
      width: calc(100% - 32px);
      margin: 0 auto;
  }
}

@include breakpoint-min('md') {
  .talent-show {
    .wp-block-button {
      margin-left: 30px;
    }
    .hero-section .wp-block-cover__inner-container {
      align-items: start;
    }
  }

  section.landingPage.hero-section .carousel-caption {
    max-width: 29% !important;
  }

  .hero-section img {
    height: auto !important;
  }

  .hero-section {
    min-height: 313px !important;
  }

  .mission-section .card-section .card-group {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 24px;
    justify-content: center;
    align-items: flex-start;

    .card {
      max-width: 100%;
    }
  }

  .owl-dots {
    padding-top: 30px;
  }
  div#container-request-cta {
    display: none;
  }
  .talent-show .hero-section {
    .wp-block-buttons {
      display: block;
    }
  }
  .talent-show {
    #sm-request-btn{
      display: inline-flex;
    }
  }
  .social-media-content {
    width: calc(100% - 48px);
  }
}

@include breakpoint-max('lg') {
  .carousel-section .owl-carousel h2 {
    font-size: $font-size-md !important;
    margin: 10px 0 30px !important;
  }

  .monm-banner {
    p {
      padding-left: 1rem;
    }
  }

  .testimonial-block {
    p {
      padding: 1rem !important;
    }
  }

  .testimonial-block {
    .wp-block-media-text__media {
      margin: 0;
    }
  }

  .testimonals-paragraph {
    padding: 10px 0;
  }

  .testimonals-paragraph p {
    font-size: $font-size-base !important;
  }

  .banner-heading {
    padding-left: 3rem;
  }

  .bod-advisory-heading {
    font-size: $font-size-lg !important;
    padding: 20px 0 !important;
    line-height: 24px !important;
  }

  .advisroy-row .advisory-board-member {
    font-size: $font-size-lg !important;
  }

  .bod-row {
    flex-wrap: wrap;
  }

  .bod-row .bod-col {
    max-width: 40%;
    flex-basis: auto !important;
    width: 40%;
  }

  .mission-section {
    .card-section {
      .card-group {
        padding: 0px 30px 40px;
      }
    }
  }

  section.featured-article-section {
    @include common-padding-for-40;
  }

  .owl-dots {
    padding-top: 30px;
  }
}

@include breakpoint-between(481, 767) {
  .hero-section {
    height: auto !important;
    min-height: 40% !important;
    width: 100%;
    width: 100%;
  }
}

@include breakpoint-between(540, 599) {
  .learningCarousel .carousel-card img {
    width: 100%;
    height: 430px;
    margin: 0 auto;
    text-align: center;
    object-fit: cover;
  }
}

@include breakpoint-between(991, 1290) {
  .hero-section {
    height: auto !important;
    width: 100%;
    width: 100%;
  }

  .carousel-section .owl-carousel h2 {
    font-size: $font-size-md !important;
    margin: 10px 0 30px !important;
  }

  .carousel-section {
    max-width: 95%;

    .owl-carousel {
      .owl-nav {
        button {
          &.owl-prev {
            left: -45px;
            font-size: $font-size-6sm;
          }

          &.owl-next {
            right: -45px;
            font-size: $font-size-6sm;
          }
        }
      }
    }
  }
}


@include breakpoint-max-custom(380) {
  section.landingPage {
    &.hero-section {
      div#carouselExampleCaptions {
        height: 150px;
      }
    }
  }

  .generic-duplex-section .testimonials-section {
    grid-template-columns: 1fr;
  }

  .button-row {
    gap: 0px;
  }

  section.landingPage.hero-section .carousel-indicators {
    bottom: 10px;
  }
}

@include breakpoint-max-custom(390) {
  .ticket-donations .wp-block-button.btn.btn-orange {
    width: calc(100% - 20px) !important;
  }
}

@include breakpoint-max-custom(991) {
    .talent-show-section .video-details-section {
      grid-template-columns: repeat(2, 1fr);
    }
  
    .ticket-donation-banner img.wp-block-cover__image-background {
      height: 100% !important;
      margin-bottom: 0 !important;
      padding-bottom: 0 !important;
    }
  
    .media-with-left-caption-images,
    .media-with-right-caption-images {
      width: auto;
      display: grid;
      grid-template-columns: 1fr;
      padding: 1rem;
  
      .caption-image {
        width: 100%;
        height: 352px;
      }
  
      .btn-orange {
        width: 100%;
        padding: 1rem;
        text-align: center;
  
        a {
          text-decoration: none;
          color: $color-black;
          font-weight: bold;
        }
      }
    }
  
    .hero-textcenter-section {
      padding: 40px 0;
    }
  
    section.landingPage {
      &.hero-section {
        .carousel-caption {
          max-width: 30%;
          right: auto;
          bottom: 0px;
          margin: 0 auto;
  
          h1 {
            font-size: $font-size-xl;
          }
        }
      }
    }
  
    .carousel-indicators {
      button.carousel-dot {
        width: 8px;
        height: 8px;
      }
    }
  
    .board-card {
      h3 {
        font-size: $font-size-md !important;
        line-height: normal !important;
      }
    }
  
    .talent-show-blue-card {
      .dream-notes-block {
        .content-section {
          grid-template-columns: 1fr;
        }
      }
    }
  
    .talent-show-blue-card {
      .dream-notes-block {
        .content-section {
          figure {
            img {
              width: 100%;
            }
          }
        }
      }
    }
  
    .board-of-director-section {
      padding: 0px 0 40px;
  
      .generic_quad {
        grid-template-columns: repeat(3, 1fr);
        margin-top: 40px;
      }
    }
  
    .pink-section {
      .image-text-block {
        display: grid;
        grid-template-columns: 1fr 1fr;
  
        figure {
          img {
            width: 100%;
          }
        }
      }
    }
  
    .hero-textcenter-section {
      .hero-textcenter {
        margin-bottom: 40px;
        padding: 0 20px;
  
        h5 {
          font-size: $font-size-lg;
        }
      }
    }
  
    .leadership-section {
      .leadership-card {
        grid-template-columns: repeat(3, 200px);
        margin-bottom: 0px !important;
  
        .leadership {
          img {
            width: 100%;
          }
        }
      }
    }
  
    .generic-duplex-section .testimonials-section .testimonial img {
      width: 100%;
    }
  
    .generic-text h1 {
      font-size: $font-size-3xl;
    }
  
    h2 {
      font-size: $font-size-2xl;
    }
  
    .card h2 {
      font-size: calc(1.3rem + 0.6vw) !important;
      text-align: center;
    }
  
    .mission-section {
      .mission-article {
        margin-top: 50px !important;
        margin-bottom: 20px !important;
  
        .heading {
          h1 {
            font-size: $font-size-4md;
            line-height: normal;
          }
        }
      }
    }
  
    .mission-pillar-section {
      .article-pillars {
        h4 {
          margin: 0 20px;
        }
      }
    }
  
    .generic-triptych-section {
      .video-section {
        .video-details-section {
          grid-template-columns: 1fr 1fr;
        }
      }
    }
  
    .carousel-caption {
      h1 {
        font-size: 46px;
        line-height: normal;
        margin-bottom: 0;
      }
  
      h6 {
        font-size: $font-size-sm;
        margin-bottom: 0 !important;
      }
  
      p {
        font-size: $font-size-md;
      }
    }
  
    .mission-section {
      .mission-article {
        .heading {
          h1 {
            padding: 0 20px !important;
          }
        }
      }
    }
  
    .container,
    .container-md,
    .container-sm {
      max-width: 960px !important;
    }
  
    .pillars-card.mission-piller {
      flex-wrap: wrap !important;
    }
  
    .hero-textcenter-section {
      .hero-textcenter {
        padding: 0 10px;
      }
    }
  
    .subcategory h1 {
      font-size: $font-size-3xl;
    }
  
    .generic-duplex-section {
      .testimonials-section {
        .testimonial {
          grid-template-columns: 1fr;
        }
      }
    }
  
    .welcome-text {
      padding: 0 20px !important;
    }
  
    .category-container {
      padding-left: 20px !important;
      padding-right: 20px !important;
    }
  
    .blog-wrapper .blog-content-wrapper {
      padding-left: 20px !important;
      padding-right: 20px !important;
    }
  
    .see-all-stories {
      .stories-badge-wrapper {
        margin-left: 10px !important;
        gap: 10px !important;
  
        .single-story-badge {
          height: 40px !important;
          min-width: 100px !important;
          margin: 0px !important;
        }
      }
    }
  
    section.landingPage.hero-section img.d-block.w-100 {
      margin: 0 !important;
      padding: 0 !important;
    }
  
    section.landingPage.hero-section .carousel-indicators {
      bottom: 25px;
    }
}

@include breakpoint-max-custom(1170) {
    .donor-advised-section {
      padding: 40px 0 40px;
    }
  
    section.landingPage.hero-section .carousel-caption {
      max-width: 300px !important;
    }
  
    .media-with-left-caption-section .media-with-left-caption-images,
    .media-with-left-caption-section .media-with-right-caption-images {
      padding: 40px 20px;
    }
  
    .mentorship__para,
    .mom-description {
      padding: 40px 20px 20px !important;
    }
  
    .community__title.mission-head {
      width: 100%;
    }
}

@include breakpoint-max-custom(1600) {
  .hero-section {
    img {
      height: auto !important;
    }
  }
}

@include breakpoint-max-custom(1400){
    .hero-textcenter-section {
      padding: 40px 0;
    }
  
    .superblockslider {
      .superblockslider__controls {
        &.superblockslider__buttons {
          bottom: 0;
          transform: translateY(-170px);
          left: 14% !important;
          margin: 0 !important;
          justify-content: flex-start;
        }
      }
    }
  
    section.landingPage {
      &.hero-section {
        .carousel-caption {
          max-width: 480px !important;
          right: auto !important;
          left: 7% !important;
          width: 30%;
  
          h1 {
            @include typography(55px, 500, Brandon Grotesque, normal);
            margin-bottom: 0px;
          }
        }
      }
    }
  
    .superblockslider__slide_inner .inner-blocks figure img {
      width: 100%;
      height: 567px;
      object-fit: cover;
    }
  
    .superblockslider__buttons {
      bottom: 20%;
      top: auto;
      left: 0 !important;
      right: 0 !important;
      margin: 0 auto !important;
      text-align: center;
      transform: none;
    }
  
    .superblockslider {
      .wp-block-cover__inner-container {
        padding: 0 50px;
      }
    }
}

@include breakpoint-max-custom(1366) {
    section.landingPage.hero-section {
      .carousel-indicators {
        bottom: 50px;
      }
    }
}

@include breakpoint-max-custom(1280) {
    section.landingPage {
      &.hero-section {
        .carousel-caption {
          max-width: 340px !important;
          width: 100%;
        }
      }
    }
  
    .banner-heading {
      padding-left: 3rem;
    }
  
    .mission-section .mission-article {
      margin: 40px auto 0px;
  
      .heading {
        h1 {
          font-size: $font-size-4xl;
          line-height: normal;
        }
      }
    }
}

@include breakpoint-max-custom(1200) {
    .mp-paragraph {
      padding: 0 30px !important;
  
      .community__title {
        width: 100%;
      }
  
      .paragraph {
        padding: 40px 0px !important;
      }
    }
  
    .mom-description {
      padding: 0px;
    }
  
    .paragraph {
      margin: 0 auto;
      width: 100%;
      padding: 40px 20px !important;
    }
  
    .mentorship__para {
      width: 100% !important;
    }
  
    .talent-show-section .video-details-section .video-details iframe {
      width: 100%;
    }
  
    .donate__page__para {
      padding: 0 30px;
      margin: 40px auto;
    }
  
    .media-with-left-caption-section .media-with-left-caption-images .caption-image img,
    .media-with-left-caption-section .media-with-right-caption-images .caption-image img {
      width: 100%;
    }
  
    .carousel-blog-section h2,
    .carousel-blog-section h5 {
      padding: 0 10px 0 !important;
    }
  
    .wp-block-cover.alignfull.hero-section {
      position: relative;
      width: 100%;
      overflow: hidden;
    }
  
    .talent-show-section {
      &.generic-triptych-section {
        padding: 0 20px;
      }
    }
  
    .sponsorship-section {
      padding: 80px 30px 50px;
    }
  
    .pink-section {
      .image-text-block {
        padding: 0 20px;
      }
    }
  
    .hero-textcenter {
      h6 {
        font-size: $font-size-lg;
        margin: 15px 0;
        line-height: normal !important;
      }
    }
  
    section.landingPage {
      &.hero-section {
        .carousel-caption {
          margin-left: 0 !important;
  
          h1 {
            font-size: 35px;
          }
        }
      }
    }
    .carousel-caption {
      bottom: 0 !important;
    }
  
    .carousel-control-next-icon,
    .carousel-control-prev-icon {
      background-size: 60% 100% !important;
    }
  
    .m-show {
      display: block;
    }
  
    .post-description .paragraph {
      padding: 20px 20px 0 !important;
    }
  
    .generic-text {
      @include common-bottom-padding-for-40;
    }
  
    .carousel-section {
      @include common-bottom-padding-for-40;
      overflow: hidden;
    }
  
    section.featured-article-section {
      @include common-bottom-padding-for-40;
    }
  
    .wordcloud-fpo-section {
      @include common-bottom-padding-for-40;
    }
  
    footer .footer {
      padding: 0 30px;
    }
  
    .generic-duplex-section {
      .generic-duplex {
        .generic-thumbnails {
          margin: 40px 20px;
  
          ul {
            li {
              img {
                width: 100%;
              }
            }
          }
        }
      }
    }
  
    .generic-triptych-section {
      .video-section {
        .video-details-section {
          .video-details {
            max-width: 100%;
  
            img {
              width: 100%;
            }
          }
        }
      }
    }
  
    .generic-text.top-0 {
      padding-top: 0 !important;
    }
  
    .generic-triptych-section {
      .container {
        max-width: 100%;
      }
    }
  
    .container {
      max-width: 1080px;
    }
  
    section.featured-article-section {
      &.light-pink-bg {
        @include common-bottom-padding-for-40;
      }
    }
  
    .subcategory {
      .light-pink-bg {
        padding-bottom: 40px !important;
      }
  
      .category-container {
        max-width: 1080px;
        margin: 0 auto;
        padding: 0 20px;
      }
    }
  
    .see-all-stories {
      padding: 35px 20px !important;
    }
  
    .carousel-indicators {
      bottom: 0;
    }
  
    section.home.hero-section .carousel-item .carousel-caption h1 {
      margin-top: 12% !important;
    }
  
    section.home {
      &.hero-section {
        .carousel-item {
          .carousel-caption {
            h1 {
              max-width: 75%;
              font-size: 50px;
              line-height: normal;
            }
          }
        }
      }
    }
  
    .home .carousel-caption h1 {
      max-width: 100%;
    }
  
    .generic-text-section {
      h3 {
        font-size: xx-large !important;
      }
    }
  
    .landing-page-images {
      height: auto !important;
      width: 100%;
      display: grid;
      background: $color-blue;
  
      .left-section {
        color: $color-white;
        height: auto;
        width: 100%;
        margin: auto;
  
        h2,
        p {
          color: $color-white !important;
        }
  
        .text-group {
          height: 458px;
          justify-content: center !important;
        }
      }
  
      .right-section {
        width: 100%;
        height: auto;
        object-fit: cover;
        background: blue !important;
  
        figure,
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  
    .dream-notes-description {
      margin: 40px auto 40px !important;
      padding: 0 20px;
    }
  
    .dream-notes-block {
      padding: 0 20px;
    }
  
    .grantspanel {
      flex-wrap: unset;
    }
  
    .season-of-giving-section {
      padding: 0 20px;
      margin: 40px auto 40px;
    }
  
    .word-cloud-section {
      padding: 0 20px;
    }
  
    section.featured-article-section {
      padding-left: 20px;
      padding-right: 20px;
    }
  
    .blog-wrapper .category-container {
      padding: 0 20px;
    }
}

@include breakpoint-max-custom(767) {
    .blog-paragraph {
      padding: 30px 20px !important;
    }
  
    .view-large {
      display: none;
    }
  
    .view-small {
      display: unset !important;
  
      a {
        text-decoration: none;
      }
    }
  
    .hero-section .wp-block-cover__inner-container {
      max-width: 39% !important;
      position: absolute;
      left: 0;
    }
  
    section.landingPage h2 {
      font-size: $font-size-xl !important;
      line-height: normal !important;
    }
  
    .learningCarousel .carousel-card img {
      width: 100%;
      height: 200px;
    }
  
    .hero-textcenter-section {
      padding: 0px !important;
    }
  
    .hero-textcenter-section .hero-textcenter {
      padding: 0px !important;
    }
  
    .grantsblock {
      padding-top: 0px !important;
    }
  
    .sponsorship-section {
      padding: 1rem !important;
    }
  
    .paragraph h5.wp-block-heading {
      margin-top: 20px;
      font-size: $font-size-sm !important;
    }
  
    .dream-notes-description {
      margin: 30px auto 40px;
    }
  
    .getinvoled__text h6 {
      font-size: $font-size-md !important;
    }
  
    footer .footer .footer-link-section .footer-column li,
    footer .footer .footer-link-section .footer-column p {
      font-size: $font-size-sm !important;
      line-height: normal;
    }
  
    .ticket-donations-card-section {
      flex-direction: column;
      padding: 0 20px;
    }
  
    .ticket-donations-card h3 {
      margin-bottom: 20px !important;
    }
  
    .ticket-donate-btn-section {
      .button-row {
        // max-width: 93% !important;
  
        .btn {
          color: $text-color;
          margin: 5px 10px;
        }
      }
    }
  
    .hero-section {
      height: auto !important;
      width: 100%;
  
      .wp-block-cover__inner-container {
        max-width: unset !important;
      }
    }
  
    
  
    .carousel-indicators {
      button.carousel-dot {
        width: 8px !important;
        height: 8px !important;
      }
    }
  
    .community-section {
      padding: 40px 0;
  
      .article-heading {
        h2 {
          font-family: brandon grotesque bold !important;
          font-size: $font-size-3xl !important;
        }
      }
  
      .generic_quad {
        grid-template-columns: repeat(3, 1fr);
      }
    }
  
    .talent-show-section {
      .video-details-section {
        grid-template-columns: 1fr 1fr;
        align-items: center;
        justify-content: center;
        margin: 0 auto;
      }
    }
  
    .talent-show-blue-card {
      .dream-notes-block {
        display: grid;
        grid-template-columns: 1fr;
        padding: 0;
  
        h3 {
          font-size: $font-size-lg !important;
          margin-bottom: 20px !important;
        }
      }
  
      .wp-block-column {
        padding-right: 0;
      }
    }
  
    .board-of-director-section {
      .generic_quad {
        grid-template-columns: repeat(2, 1fr);
      }
    }
  
    .hero-textcenter-section {
      .hero-textcenter {
        padding: 30px 20px 0;
      }
    }
  
    .pink-section {
      .image-text-block {
        grid-template-columns: 1fr;
      }
    }
  
    .generic-triptych-section {
      .video-section {
        .video-details-section {
          grid-template-columns: 1fr;
        }
      }
    }
  
    .mission-section {
      section.featured-article-section {
        .featured-article {
          grid-template-columns: 1fr;
  
          img {
            width: 100%;
          }
  
          s .article-content {
            order: 1;
          }
        }
      }
  
      .card-section {
        .card-group {
          display: grid;
          grid-template-columns: 1fr 1fr;
          padding: 0px 10px 40px;
  
          .card {
            max-width: 100%;
          }
        }
      }
    }
  
    .carousel-caption {
      h1 {
        font-size: $font-size-4xl;
        line-height: normal;
        margin-bottom: 0;
      }
  
      h6 {
        font-size: $font-size-sm;
        margin-bottom: 0 !important;
      }
  
      p {
        font-size: $font-size-md;
      }
    }
  
    section.home.hero-section .carousel-item .carousel-caption h1 {
      margin-top: 24% !important;
    }
  
    section.featured-article-section {
      .featured-article {
        grid-template-columns: 1fr;
        justify-content: center;
        text-align: center;
        order: 1;
  
        img {
          width: 100%;
        }
      }
  
      &.top-80 {
        @include common-bottom-padding-for-40;
      }
    }
  
    h3 {
      font-size: $font-size-lg;
    }
  
    p {
      font-size: $font-size-md;
      line-height: 140%;
    }
  
    .btn {
      font-size: $font-size-base;
      font-weight: $font-weight-strong;
      padding: 8px;
      white-space: nowrap;
    }
  
    section.home.hero-section {
      .carousel-inner {
        height: 250px;
  
        .carousel-item {
          height: 250px;
  
          img {
            height: 100%;
            background-size: cover;
            object-fit: cover;
            background-position: 100px 100px;
            height: 250px;
          }
  
          .carousel-caption {
            top: 0;
            display: block !important;
          }
        }
      }
    }
  
    .mission-section {
      .card-section {
        .card-group {
          display: grid;
          grid-template-columns: 1fr 1fr;
  
          .card {
            max-width: 100%;
          }
        }
      }
    }
  
    .carousel-caption {
      top: 0rem;
  
      p {
        font-size: $font-size-base;
      }
    }
  
    section.home.hero-section {
      .carousel-indicators {
        bottom: 12% !important;
      }
    }
  
    h2 {
      font-size: $font-size-3xl;
    }
  
    h3 {
      font-size: $font-size-xl !important;
      margin-bottom: 0 !important;
    }
  
    p {
      font-size: $font-size-md;
    }
  
    .pos-left {
      order: -1;
      text-align: left;
    }
  
    .hero-textcenter-section {
      padding: 40px 0 40px;
    }
  
    .hero-textcenter {
      h3 {
        margin-bottom: 20px !important;
      }
    }
  
    header {
      nav {
        .header-button {
          top: 25px !important;
          margin-right: 15px;
        }
      }
    }
  
    .paragraph {
      @include common-padding-for-40;
    }
  
    .owl-theme .owl-nav {
      display: none !important;
    }
  
    .owl-dots {
      padding-top: 30px;
    }
  
    .generic-duplex-section {
      .testimonials-section {
        .testimonial {
          h3 {
            font-size: $font-size-md !important;
          }
        }
      }
  
      &.top-40 {
        &.bottom-80 {
          padding: 0 20px;
        }
      }
    }
  
    .subcategory .container {
      padding: 0 !important;
    }
  
    // financials
    .financials-section {
      max-width: 1080px;
      margin: 0 auto;
      padding: 10px 0 40px !important;
  
      .article-heading {
        h2 {
          font-family: brandon grotesque bold !important;
          font-size: $font-size-5xl;
          font-weight: $font-weight-strong;
        }
      }
    }
  
    .financials-section .list-points {
      margin-bottom: 20px;
    }
  
    .financials-section .list-points ul {
      list-style: disc;
      padding-left: 20px;
      line-height: 30.8px;
    }
  
    .financials-section .list-points ul li a {
      color: $text-color;
      font-size: $font-size-xl;
      font-family: brandon grotesque medium !important;
      font-size: $font-size-2xl !important;
    }
  
    h2 {
      font-size: $font-size-2md;
    }
  
    h3 {
      font-size: $font-size-xl !important;
      margin-bottom: 0 !important;
    }
  
    p {
      font-size: $font-size-md;
    }
  
    .pos-left {
      order: 1;
      text-align: left;
    }
  
    .hero-textcenter-section {
      padding: 40px 0 40px;
    }
  
    .hero-textcenter {
      h3 {
        margin-bottom: 20px !important;
      }
    }
  
    header {
      nav {
        .header-button {
          top: 25px !important;
          margin-right: 15px;
        }
      }
    }
  
    .paragraph {
      @include common-padding-for-40;
      width: 100% !important;
    }
  
    .owl-theme .owl-nav {
      display: none !important;
    }
  
    .owl-dots {
      padding-top: 30px;
    }
  
    .generic-duplex-section {
      .testimonials-section {
        .testimonial {
          h3 {
            font-size: $font-size-md !important;
          }
        }
      }
    }
  
    .subcategory .container {
      padding: 0 !important;
    }
  
    .article-content {
      padding: 0 0px;
    }
  
    .financials-section {
      .list-points {
        ul {
          li {
            a {
              font-size: $font-size-lg !important;
            }
          }
        }
      }
    }
  
    .story-banner {
      section.home.hero-section .carousel-item .carousel-caption h1 {
        display: none;
      }
    }
  
    .blog-wrapper .blog-content-wrapper .blog-content__item {
      padding: 0px 0px 0px 0px !important;
    }
  
    .what-we-do-section {
      &.our-work-title-and-text {
        img {
          width: 100% !important;
        }
  
        figure.wp-block-media-text__media {
          width: 100%;
        }
      }
    }
  
    .scholar-testimonials {
      padding: 40px 20px 20px !important;
    }
  
    .grantspanel {
      flex-wrap: wrap;
    }
  
    .season-of-giving-section {
      h3 {
        font-size: $font-size-xl !important;
      }
    }
  
    .season-of-giving-content-img {
      overflow: hidden;
      margin-left: 0 !important;
      margin-right: 0 !important;
  
      img {
        margin-left: 0 !important;
        margin-right: 0 !important;
        height: auto;
      }
    }
  
    .leadership-section {
      .leadership-card {
        grid-template-columns: repeat(2, 200px);
  
        .info-card {
          max-width: 180px;
        }
      }
    }
  
    .leadership {
      margin: 0 auto;
    }
  
    .carousel-indicators {
      margin: 0 0 18px auto !important;
      left: 12%;
      right: 0;
    }
  
    section.landingPage {
      &.hero-section {
        .carousel-caption {
          max-width: 30% !important;
        }
      }
  
      h2 {
        font-size: 22px;
      }
    }
}

@include breakpoint-max-custom(680){
    section.mission-section {
      padding: 30px 0 !important;
    }
  
    .ticket-donate-btn-section .button-row .wp-block-buttons {
      flex-direction: column;
      align-items: center;
    }
  
    .learningCarousel .carousel-card img {
      width: 100%;
      height: 190px;
    }
  
    .hero-textcenter-section {
      margin: 3rem 1rem;
    }
  
    .hero-section img {
      height: auto !important;
    }
  
    section.landingPage.hero-section {
      .carousel-caption {
        left: 6% !important;
      }
    }
  
    .mission-section {
      .mission-article {
        .heading {
          h1 {
            padding: 0 20px !important;
            font-size: $font-size-4xl;
            line-height: normal !important;
          }
        }
      }
    }
  
    .container,
    .container-sm {
      max-width: 100%;
    }
  
    footer {
      .footer {
        padding: 0 10px;
      }
    }
  
    .paragraph {
      padding: 40px 20px !important;
    }
  
    section {
      &.generic-wide-section {
        .generic-wide-thumbs {
          gap: 8px;
          margin-top: 10px;
        }
      }
    }
  
    .btn.btn-orange {
      width: 100% !important;
    }
  
    .get-involved-section {
      .pillars {
        h3 {
          margin-bottom: 20px !important;
          display: block;
        }
      }
    }
  
    .button-row {
      flex-direction: column;
      gap: 10px;
      // margin: 10px !important;
      display: flex;
      width: 100% !important;
      max-width: 96% !important;
  
      .btn {
        font-size: $font-size-sm !important;
      }
    }
  
    .see-all-stories .stories-badge-wrapper {
      margin-left: 0 !important;
    }
  
    section.landingPage.hero-section {
      height: 250px;
      display: inline-block;
    }
  
    section.landingPage.hero-section div#carouselExampleCaptions {
      height: auto;
    }
  
    section.landingPage.hero-section div#carouselExampleCaptions .carousel-inner .carousel-item img.d-block.w-100 {
      height: 250px;
      object-fit: cover;
    }
  
    h2 {
      font-size: $font-size-lg-md;
      line-height: normal;
    }
  
    h3 {
      font-size: $font-size-base !important;
      margin-bottom: 0 !important;
      line-height: normal !important;
    }
  
    .generic-text h3 {
      font-family: brandon grotesque bold !important;
      padding-top: 2rem !important;
      font-size: $font-size-lg-md !important;
      margin-bottom: 15px !important;
    }
  
    h6.p-4 {
      line-height: normal !important;
    }
  
    .hero-textcenter-section {
      padding: 0px 0 40px;
    }
  
    .blog-wrapper .light-pink-bg {
      padding: 3px 10px !important;
    }
  
    .home .carousel-caption h1 {
      max-width: 100%;
    }
  
    section.landingPage.hero-section {
      .carousel-indicators {
        button.carousel-dot {
          width: 8px !important;
          height: 8px !important;
        }
      }
    }
  
    .mission-section {
      .mission-article {
        margin-top: 0px !important;
        margin-bottom: 10px !important;
  
        .heading {
          h1 {
            padding: 0 20px !important;
            font-size: 26px;
            line-height: normal !important;
          }
        }
      }
    }
  
    .paragraph h3 {
      margin-top: 0rem;
    }
}

@include breakpoint-max-custom(580) {
    .talent-show-section {
      .video-details-section {
        grid-template-columns: 1fr;
        width: 100% !important;
      }
    }
  
    .new-hero-banner {
      .wp-block-cover {
        min-height: min-height !important;
      }
    }
  
    section.landingPage h2 {
      font-size: $font-size-sm;
    }
  
    .mission-section .mission-article .heading h1 {
      font-size: $font-size-xl;
    }
  
    .media-with-left-caption-images,
    .media-with-right-caption-images {
      width: auto;
      display: grid;
      grid-template-columns: 1fr;
      padding: 1rem;
  
      .caption-image {
        width: 100%;
        height: 352px;
      }
  
      .content {
        width: 100%;
        margin: 0;
      }
  
      .btn-orange {
        width: 100%;
        padding: 1rem;
        text-align: center;
  
        a {
          text-decoration: none;
          color: $color-black;
          font-weight: bold;
        }
      }
    }
  
    .word-cloud-section .season-of-giving-content-img {
      margin-left: 0rem !important;
      margin-right: 0rem !important;
    }
  
    .hero-section {
      min-height: 185px !important;
    }
  
    .ticket-donate-btn-section {
      .button-row {
        // max-width: 85% !important;
  
        .wp-block-buttons {
          flex-direction: column;
        }
      }
    }
  
    .hero-section {
      h4 {
        margin: 0;
        padding: 0;
        text-align: left;
        font-size: $font-size-4xl;
      }
    }
  
    .monm-banner {
      p {
        font-size: 2rem !important;
        padding-left: 1rem;
      }
    }
  
    .testimonial-block {
      p {
        padding: 1rem !important;
      }
    }
  
    .mom-description {
      padding: 2rem;
    }
  
    .testimonial-block {
      flex-wrap: wrap;
  
      .wp-block-media-text__content {
        width: 100%;
      }
  
      .wp-block-media-text {
        width: 100%;
        flex-wrap: wrap;
      }
  
      .wp-block-media-text__media {
        width: 100%;
  
        img {
          width: 100%;
        }
      }
    }
  
    .community-section {
      .generic_quad {
        grid-template-columns: repeat(2, 1fr);
      }
    }
  
    .wp-block-cover,
    .wp-block-cover-image {
      min-height: 127px;
    }
  
    .banner-heading {
      font-size: 2rem !important;
      color: $color-white !important;
      padding-left: 2rem !important;
    }
  
    .bod-row {
      grid-template-columns: 1fr !important;
      gap: 5px;
    }
  
    .advisroy-row {
      display: grid;
      grid-template-columns: 1fr;
      gap: 0px;
      margin: 1rem;
  
      .advisory-board-member {
        font-weight: bold !important;
        font-size: $font-size-lg;
      }
    }
  
    .bod-row .bod-col h6 {
      font-size: $font-size-lg !important;
      margin: 10px 0 !important;
    }
  
    .bod-row .bod-col {
      width: 90%;
      max-width: 90%;
    }
  
    .hero-section {
      min-height: fit-content !important;
    }
}

@include breakpoint-max-custom(540) {
    .learningCarousel .carousel-card img {
      width: 100%;
      height: 546px;
      max-width: 546px;
    }
  
    .our-work-sub-group {
      padding: 0rem 1rem;
    }
  
    .financial-section-body {
      padding-top: 0px !important;
    }
  
    .godcontainer {
      padding-left: 0px !important;
      padding-right: 0px !important;
    }
  
    .mentorship__para {
      @include common-bottom-padding-for-40;
    }
  
    .wp-block-group.season-of-giving-section {
      .season-of-giving-content {
        margin: 0 !important;
      }
    }
}

@include breakpoint-max-custom(480) {
    .learningCarousel .carousel-card img {
      width: 100%;
      height: 350px;
      max-width: 546px;
      object-fit: cover;
    }
  
    .hero-textcenter-section {
      margin: 0rem 1rem;
    }
  
    .board-of-director-section {
      .generic_quad {
        grid-template-columns: 1fr;
      }
    }
  
    .hero-section {
      height: auto !important;
      min-height: auto !important;
      width: 100%;
  
      width: 100%;
  
      .wp-block-cover__inner-container {
        max-width: unset !important;
      }
  
      img {
        height: auto !important;
      }
    }
  
    .hero-textcenter-section {
      padding: 10px 0 40px;
    }
  
    p {
      font-size: 1rem !important;
    }
  
    .leadership-section {
      .leadership-card {
        grid-template-columns: repeat(1, 252px);
        gap: 0;
  
        .info-card {
          max-width: 100%;
        }
      }
    }
  
    .hero-section {
      .wp-block-cover__inner-container {
        max-width: unset;
        height: 100px;
        display: flex;
        justify-content: center;
        }
    }
  
    .our-work-sub-title {
      margin-top: 1.5rem;
      font-size: $font-size-xl;
    }
  
    .carousel-caption h1 {
      font-size: $font-size-md;
    }
  
    .generic-duplex-section {
      &.top-40 {
        &.bottom-80 {
          padding: 0 10px;
        }
      }
  
      .testimonials-section {
        gap: 10px;
      }
    }
  
    .subcategory {
      .category-container {
        padding: 0 20px;
      }
    }
  
    header nav .navbar-collapse {
      top: 88px !important;
    }
  
    header .header-contact-form .signup-section a.btn.btn-orange {
      height: 100%;
      top: 0px;
      right: 0px;
    }
  
    .advisory-list-section {
      grid-template-columns: 1fr;
    }
  
    .featured-article-section .welcome-text {
      margin: 0;
      padding: 0;
    }
  
    .see-all-stories {
      padding: 35px 19px;
    }
  
    .see-all-stories {
      grid-template-columns: 1fr 1fr;
    }
  
    .blog-details {
      padding: 9px 20px;
  
      h2 {
        margin-bottom: 0px;
      }
    }
  
    .blog-wrapper .blog-content-wrapper .blog-content__item .see-all-stories {
      display: grid;
      grid-template-columns: 1fr;
      align-items: center;
      gap: 15px;
    }
  
    .hero-section .wp-block-cover__inner-container {
      height: 87px;
    }
    .talent-show {
      div.paragraph {
        a.btn.btn-orange {
          font-size: $font-size-xs;
        }
      }
    }
}

@include breakpoint-max-custom(430) {
  .learningCarousel .carousel-card img {
    width: 100%;
    height: 250px;
  }
}


.landing {
  @include breakpoint-custom-min-max(320, 389) {
    .mission-section {
      .mission-article {
        margin-bottom: 10px;
        margin-top: 0;
        text-align: center;
        margin: 40px auto 0;
        .heading {
          margin: 0 auto;
          max-width: 940px;
          h1 {
            font-size: 25px;
          }
          h2 {
            font-size: 22px;
          }
        }
      }
    }
    .landingPage {      
      &.hero-section {
        div#carouselExampleCaptions  {
          height: 150px;
        }
        .carousel-indicators {
          bottom: 20px;
          left: 0;
          margin: 0 auto;
          max-width: 37%;
          text-align: center;
          width: 100%;
          .carousel-dot {
            margin: 0 5px;
            height: 8px;
            width: 8px;
          }
        }
        .carousel-inner {
         h2 {
          color: $color-white;
          font-family: 'brandon grotesque bold';
          font-size: $font-size-sm;
          font-weight: $font-weight-medium;
          line-height: 46px;
          margin-bottom: 0px;
         } 
         .btn-orange {
          font-family: 'brandon grotesque black';
          font-size: $font-size-base;
          font-style: normal;
          font-weight: $font-weight-medium;
          line-height: 16px;
         }
        }
      } 
    }
  }
  @include breakpoint-custom-min-max(390, 767) {
    .mission-section {
      .mission-article {
        margin-bottom: 10px;
        margin-top: 0;
        text-align: center;
        margin: 40px auto 0;
        .heading {
          margin: 0 auto;
          max-width: 940px;
          h1 {
            font-size: $font-size-xl;
          }
          h2 {
            font-size: $font-size-lg-md;
          }
        }
      }
    }
    .landingPage {
      &.hero-section div#carouselExampleCaptions  {
        .carousel-indicators {
          bottom: 5px;
          left: 0;
          margin: 0 auto;
          max-width: 37%;
          text-align: center;
          width: 100%;
          .carousel-dot {
            margin: 0 5px;
            height: 8px;
            width: 8px;
          }
        }
        .carousel-inner {
         h2 {
          color: $color-white;
          font-family: 'brandon grotesque bold';
          font-size: $font-size-sm;
          font-weight: $font-weight-medium;
          line-height: 46px;
          margin-bottom: 0px;
         } 
         .btn-orange {
          font-family: 'brandon grotesque black';
          font-size: $font-size-base;
          font-style: normal;
          font-weight: $font-weight-medium;
          line-height: 16px;
         }
        }      } 
    }
  }
  @include breakpoint-custom-min-max(768, 1023) {
    .mission-section {
      .mission-article {
        margin-bottom: 20px;
        margin-top: 50px;
        text-align: center;
        .heading {
          margin: 0 auto;
          max-width: 940px;
          h1 {
            font-size: $font-size-4md;
          }
          h2 {
            font-size: $font-size-2xl;
          }
        }
      }
    }
    .landingPage {
      
      &.hero-section div#carouselExampleCaptions  {
        height: auto;
        .carousel-indicators {
          left: 0;
          margin: 0 auto;
          max-width: 37%;
          text-align: center;
          width: 100%;
          right: auto;
          bottom: 25px;
          button.carousel-dot {
            margin: 0 5px;
            height: 8px;
            width: 8px;
          }
        }
      }
      .mission-section {
        h2 {
          font-size: $font-size-lg-md;
        }
      }
      &.hero-section {
        .carousel-caption {
          max-width: 29%;
          width: 29%;
        }
        .carousel-inner {
         h2 {
          color: $color-white;
          font-family: 'brandon grotesque bold';
          font-size: 25px;
          font-weight: $font-weight-medium;
          line-height: 46px;
         } 
         .btn-orange {
          font-family: 'brandon grotesque black';
          font-size: $font-size-base;
          font-style: normal;
          font-weight: $font-weight-medium;
          line-height: 16px;
         }
        }
      } 
    }
  }
  @include breakpoint-custom-min-max(1024, 1439) {

    .mission-section {
      .mission-article {
        margin-bottom: 20px;
        margin-top: 50px;
        text-align: center;
        .heading {
          margin: 0 auto;
          max-width: 940px;
          h2 {
            font-size: $font-size-5xl;
          }
        }
      }
    }

    .landingPage {
      &.hero-section div#carouselExampleCaptions  {
        height: auto;
        .carousel-indicators {
          bottom: 50px;
          button.carousel-dot {
            margin-left: 8px;
            margin-right: 8px;
            height: 14px;
            width: 14px;
          }
        }
      }
      .mission-section {
        h2 {
          color: $text-color;
          font-size: $font-size-5xl;
          font-weight: $font-weight-medium;
          line-height: 46px;
        }
      }
      &.hero-section {
        .carousel-indicators {
          bottom: 25px;
          left: 0;
          margin: 0 auto;
          max-width: 37%;
          text-align: center;
          width: 100%;
          .carousel-dot {
            margin: 0 5px;
            height: 8px;
            width: 8px;
          }
        }
        .carousel-inner {
         h2 {
          color: $color-white;
          font-family: 'brandon grotesque bold';
          font-size: $font-size-3xl-md;
          font-weight: $font-weight-medium;
          line-height: 46px;
          margin-bottom: 20px;
         } 
        }
        .btn-orange {
          font-family: 'brandon grotesque black';
          font-size: $font-size-base;
          font-style: normal;
          font-weight: $font-weight-medium;
          line-height: 16px;
         }
      } 
    }
  }
  @include breakpoint-min('xl') {
    .landingPage {
      &.hero-section div#carouselExampleCaptions  {
        height: auto;
        .carousel-indicators {
          bottom: 50px;
          button.carousel-dot {
            margin-left: 8px;
            margin-right: 8px;
            height: 14px;
            width: 14px;
          }
        }
      }
      .mission-section {
        h2 {
          color: $text-color;
          font-size: $font-size-6sm;
          font-weight: $font-weight-medium;
          line-height: 46px;
        }
      }
      & .hero-section {
        .carousel-inner {
         h2 {
          color: $color-white;
          font-family: 'brandon grotesque bold';
          font-size: $font-size-5xl;
          font-weight: $font-weight-medium;
          line-height: 46px;
          margin-bottom: 20px;
         } 
        }
        .btn-orange {
          font-family: 'brandon grotesque black';
          font-size: $font-size-base;
          font-style: normal;
          font-weight: $font-weight-medium;
          line-height: 16px;
         }
      } 
    }
  }
  .content {
    p {
      font-family: Brandon Grotesque;
    }
  }
}

.blog {
  p {
      font-family: Brandon Grotesque;
      @include breakpoint-min('md') {
        font-size: $font-size-xl;
      }
  }
  .category-container {
    h2 {
      color: text-color;
      font-size: $font-size-lg-md;
      font-weight: $font-weight-medium;
      @include breakpoint-min('md') {
        font-size: $font-size-2xl;
      }
      @include breakpoint-min('lg') {
        font-size: $font-size-5xl;
        line-height: 46px;
      }
    }
  }
}

.blog-wrapper {
  .blog-content-wrapper {
    .blog-content {
      .story__title {
        font-family: Brandon Grotesque; 
      }
    }
    .blog-content__item {
      h2 {
        color: $text-color;
        font-size: $font-size-lg-md;
        font-weight: $font-weight-medium;
        line-height: 46px;
        @include breakpoint-min('md') {
          font-size: $font-size-2xl;
        }
        @include breakpoint-min('lg') {
          font-size: $font-size-5xl;
        }
      }
      .title {
        font-family: Brandon Grotesque; 
      }
      .single-story-badge {
        font-family: Brandon Grotesque; 
      }
    }
  }
}