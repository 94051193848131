@import 'breakpoints';
@import 'variables';
@import 'mixins';


.landing {
    .carousel {
        .carousel-caption {
            .view-small {
                h1 {
                    font-family: brandon grotesque bold;
                    font-weight: $font-weight-medium !important;
                    color: $color-white;
                    margin-left: 0px;
                    font-size: $font-size-sm;
                    padding: 0px;
                    @include breakpoint-custom-min(421) {
                        font-size: $font-size-base !important;   
                    }
                    @include breakpoint-custom-min(480) {
                        font-size: $font-size-md !important;   
                    }
                    @include breakpoint-min('md') {
                        font-size: $font-size-xl-md !important;   
                    }
                }
            }
            .view-large {
                h1 {
                    font-family: brandon grotesque bold;
                    font-weight: $font-weight-strong;
                    color: $color-white;
                    margin-left: 0px;
                    font-size: $font-size-xl-md;
                    line-height: 46px;
                    padding: 0px;
                    @include breakpoint-min('md') {
                        font-size: $font-size-xl-md !important;
                        margin-bottom: .5rem;
                    }
                    @include breakpoint-min('lg') {
                        font-size: $font-size-3xl-md !important;
                        margin-bottom: 20px;
                    }
                    @include breakpoint-min('xl') {
                        font-size: $font-size-5xl !important;
                        margin-bottom: .5rem;
                        line-height: 1.2; 
                    }
                }
            }
        }
    }
    @include breakpoint-custom-min(767) {
        section.landingPage.hero-section .carousel-indicators {
            bottom: 25px;
        }
    }
    .mission-section .mission-article .heading {
        h1 {
            font-size: $font-size-lg-md;
            margin-top: 0;
            margin-bottom: .5rem;
            font-weight: $font-weight-medium !important;
            line-height: 1.2 !important;
            padding: 0 !important;
            font-family: brandon grotesque bold !important;
            color: $heading-color;
            @include breakpoint-min('md') {
                font-size: $font-size-2xl;
            }
            @include breakpoint-min('lg') {
                font-size: $font-size-5xl;
            }
            @include breakpoint-custom-min(1200) {
                font-size: $font-size-3xl;
            }
        }
    }
}

.blog-details-container {
    h1.blog-story-heading {
        font-family: Brandon Grotesque bold;
        @include breakpoint-min('xs') {
            font-size: $font-size-lg-md;
        }
    }
}
.mission-pillars {
    h1.community__title {
        font-size: $font-size-2md !important; // Need to remove from cms
        font-weight: 500;
        font-family: 'Brandon Grotesque bold';
        @include breakpoint-min('lg') {
            font-size: $font-size-5xl !important; // Need to remove from cms
        }
    }
}
.blog {
    h1.blog-main-title {
        font-family: 'Brandon Grotesque bold';
        font-size: 42px;
        padding-bottom: 40px;
        margin-top: 20px;
        line-height: 46px;
        @include breakpoint-min('xs') {
            font-size: $font-size-lg-md; // Need to remove from cms
        }
        @include breakpoint-min('md') {
            font-size: $font-size-2xl; // Need to remove from cms
        }
        @include breakpoint-min('lg') {
            font-size: $font-size-5xl; // Need to remove from cms
        }
    }
    .blog-content__item {
        h1.item-title {
            font-family: 'Brandon Grotesque bold';
            font-size: $font-size-5xl;
            margin-top: 20px;
            @include breakpoint-min('xs') {
                font-size: $font-size-lg-md;
            }
            @include breakpoint-min('md') {
                font-size: $font-size-2xl;
            }
            @include breakpoint-min('lg') {
                font-size: $font-size-5xl;
            }
        }
    }
}
.blog-content__item {
    h1.item-title {
        font-family: 'Brandon Grotesque bold';
        font-size: $font-size-5xl;
        margin-top: 20px;
        color: $color-black;
        line-height: 46px;
        @include breakpoint-min('xs') {
            font-size: $font-size-lg-md;
        }
        @include breakpoint-min('md') {
            font-size: $font-size-2xl;
        }
        @include breakpoint-min('lg') {
            font-size: $font-size-5xl;
        }
    }
}
