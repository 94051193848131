@import '../../../style/_variables.scss';
@import '../../../style/_mixins.scss';
@import '../../../style/_breakpoints.scss';

footer {
  background: $blue-bg;
  color: $color-white;

  .footer {
    max-width: 1280px;
    margin: 0 auto;
    padding-bottom: 40px;

    .follow-section {
      padding: 35px 0;
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      border-bottom: 1px solid $gray-color;

      h3 {
        margin-bottom: 10px !important;
        grid-column: 1/-1;
      }

      ul {
        li {
          list-style-type: none;
        }

        &.social-icons {
          display: flex;
          gap: 25px;
          margin-bottom: 0;
          align-items: center;
          padding-left: 0 !important;
        }
      }

      .footer-logo {
        grid-column: 2/-1;
        justify-content: flex-end;
        text-align: right;
      }
    }

    .footer-link-section {
      padding: 40px 0;
      display: grid;
      grid-template-columns: 389px auto auto auto;
      gap: 24px;

      .btn {
        margin-left: 0;
        background: rgb(250, 169, 70);
        width: 126px;
        border-radius: 0;
        height: 48px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-family: 'Brandon Grotesque black';
        &.btn-orange {
          &:hover {
            color: $color-black;
          }
        }
        @include breakpoint-max-custom($breakpoint-md) {
          font-size: 11.43px;
        }
      }

      .footer-column {
        &__combined {
          margin-top: 40px;

          &:nth-child(1) {
            margin-top: 0 !important;
          }
        }

        &.footer-description {
          display: grid;
          gap: 20px;

          h6 {
            margin-bottom: 0 !important;
          }

          strong {
            font-weight: $font-weight-medium;
            font-size: 18px !important;
            line-height: 25.2px;
            font-family: 'Brandon Grotesque';

            img {
              margin-right: 10px;
            }
          }

          p br {
            display: unset !important;
          }
        }

        h6 {
          font-size: $font-size-sm;
          font-family: Brandon Grotesque bold !important;
          font-weight: $font-weight-medium;
          line-height: 100%;
          letter-spacing: 1.4px;
          text-transform: uppercase;
          margin-bottom: 20px;
          text-align: left;
          color: $color-white !important;
        }

        li,
        p {
          font-size: 12px !important;
          font-family: Ringside Regular;
          font-weight: 325;
          line-height: 18px;
          font-family: 'Brandon Grotesque';
        }

        ul {
          padding: 0 !important;
          margin: 0 !important;
          list-style-type: none;
          display: grid;
          gap: 10px;
        }
      }
    }

    .copyright {
      color: $gray-color;
      border-top: 1px solid $gray-color;
      padding: 34px 0 0;
      font-family: Ringside Regular;

      ul {
        margin-bottom: 0;
        display: flex;
        justify-content: space-between;
        padding: 0 !important;
        flex-wrap: wrap;
        gap: 10px;

        li {
          list-style-type: none;
          font-family: 'Brandon Grotesque';
          font-weight: $font-weight-medium-bold;
        }
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  footer {
    .footer {
      padding: 0 30px;

      .footer-link-section {
        grid-template-columns: 300px auto auto auto;
        gap: 20px;
        margin-bottom: 1rem;

        .footer-column {
          h3 {
            margin-top: 1rem;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 991px) {
  footer {
    .footer {
      .footer-link-section {
        grid-template-columns: 1fr 1fr 1fr;
      }
    }
  }
}

@media screen and (max-width: 767px) {
  footer {
    .footer {
      .footer-link-section {
        grid-template-columns: 1fr 1fr;

        .footer-column {
          &.footer-description {
            grid-column: 1/-1;
            order: -1;
          }
        }
      }

      .copyright {
        ul {
          flex-wrap: wrap;
          gap: 20px;
          padding: 0 0px 30px;
          font-size: $font-size-sm;
        }
      }

      .follow-section {
        padding: 35px 0;
        display: flex;
        flex-direction: column;
        border-bottom: 1px solid $gray-color;
        justify-content: center;
        text-align: center;
        align-items: center;

        ul.social-icons {
          margin-bottom: 30px;

          &:hover {
            animation: none !important; /* Safari and Chrome */
          }
        }
      }
    }
  }
}

@media screen and (max-width: 580px) {
  footer {
    .footer {
      .follow-section {
        ul.social-icons {
          gap: 18px;
        }
      }
    }
  }
}

@media screen and (max-width: 480px) {
  footer {
    .footer {
      .follow-section {
        ul.social-icons {
          gap: 10px;
        }
      }
    }
  }
}
