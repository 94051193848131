@import './_variables.scss';
@import './_mixins.scss';
@import './_breakpoints.scss';
//Wo we are

.who-we-are {
  .getinvolved__cards__wrapper {
    .wp-block-heading  {
      padding-bottom: 20px;
    }
  }
}
//leadership 
.leadership {
  .leadership-block{
    .has-small-font-size {
      font-family: 'Brandon Grotesque';
      
    }
  }
  .has-text-align-center {
    font-family: 'Brandon Grotesque';
  }
  @include breakpoint-between(320, 767) {
    .wp-block-group {
      .bod-row {
        display: flex;
        gap: 24px;
        margin: 0 auto;
        max-width: 1080px;
        width: 90%;
        flex-wrap: wrap;
      }
    }
    .bod-row {
      flex-wrap: wrap;
      .bod-col {
        max-width: 90%;
        width: 90%;
        flex-basis: auto;
        padding-bottom: 0px;
        .wp-block-image {
          text-align: center;
        }
      }
    }
  }
  @include breakpoint-between(768, 1023) {
    .bod-row {
      .bod-col {
        flex-basis: auto;
        max-width: 40%;
        width: 40%;
      }
    }
    .paragraph {
      p {
        font-size: $font-size-xl;
      }
    }
    p.has-small-font-size {
      font-size: $font-size-xl;
    }
  }
  @include breakpoint-between(1024, 1440) {
    h3.wp-block-heading.bod-heading {
      padding: 40px 0;
      margin: 0 auto;
    }
    .god-heading {
      .bod-heading {
        padding: 40px 0;
        margin: 0 auto;
      }
    }
    .wp-block-group {
      .bod-row {
        display: flex;
        gap: 24px;
        margin: 0 auto;
        max-width: 1080px;
        width: 90%;
        flex-wrap: inherit;
      }
    }
    
    
  }
  @include breakpoint-min('xl') {
  
  }
}
// mission-pillar
.mission-pillars {
    @include breakpoint-between(320, 767) {
      .community__title {
        padding: 30px 0 20px;
        font-size: $font-size-2md;
        &.wp-block-heading {
          font-family: 'Brandon Grotesque bold';
          line-height: normal;
          font-size: $font-size-2md !important; //overriding the inline css
        }
      }
    }
    @include breakpoint-between(768, 1023) {
      .community__title {
        &.wp-block-heading {
          font-family: 'Brandon Grotesque bold';
          line-height: normal;
          font-size: $font-size-2md !important; //overriding the inline css
        }
      }
      .paragraph p {
        font-size: $font-size-xl;
      }
      
    }
    @include breakpoint-between(1024, 1440) {
    }
    @include breakpoint-min('xl') {
    
    }
    .mission-pillar-card-section {
    max-width: 1080px;
    margin: 0 auto 80px;
    .getinvoled__card {
      h2 {
        min-height: auto;
        margin-bottom: 20px;
      }
      p {
        font-family: 'Brandon Grotesque';
      }
      
    }
  
    .inner-card-container {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: flex-start;
      gap: 20px;
  
      .getinvoled__card {
        width: 344px;
        min-height: 705px;
        justify-content: flex-start;
        //use important for override the inline cms css
        padding-top: 20px !important;
        text-align: center;
        img {
          width: 304px !important;
          height: 257px !important;
        }
        p {
          padding: 0 20px;
        }
      }
    }

    }
}


// board of directors
.banner-heading {
    font-size: 5rem !important;
    color: $color-white !important;
    margin-left: 14rem;
    padding-left: 11rem;
}

.bod-row {
    display: flex;
    justify-content: center !important;
    gap: 24px;
    max-width: 1080px;
    width: 90%;
    margin: 0 auto;
  
    .bod-col {
      justify-content: center !important;
      max-width: 252px;
      padding-bottom: 40px;
      width: 25%;
      flex-basis: auto !important;
  
      img {
        max-width: 100%;
        height: auto;
      }
  
      p.has-text-align-center {
        text-align: center !important;
        font-size: $font-size-md !important;
        line-height: 25px !important;
      }
  
      h6 {
        text-align: center !important;
        color: $color-black !important;
        text-transform: capitalize !important;
        margin: 20px 0;
        font-size: $font-size-3xl;
        line-height: 36px !important;
      }
  
      .bod-col-image {
        justify-content: center !important;
      }
  
      .wp-block-image {
        justify-content: center !important;
        text-align: center !important;
      }
    }
  }

.bod-heading {
    margin: 2rem 0;
    font-size: 2rem;
}

h3.wp-block-heading.bod-heading {
    margin: 0 auto;
    text-align: left;
    display: flex;
    justify-content: flex-start;
    width: 90%;
    padding: 40px 0;
    max-width: 1080px;
}

.bod-heading {
  @include breakpoint-max('lg') {
        margin: 2rem 6.5rem;
    }
    @include breakpoint-max('md') {
      margin: 0rem 1rem;
  }
  @include breakpoint-max('sm') {
    margin: 0rem 1rem;
    font-size: 1.4rem !important;
}
}

h3.wp-block-heading.bod-heading {
  @include breakpoint-max('lg') {
    padding: 10px 0;
  }
}

// foundation-partners
.foundation-partners {
  p {
    font-family: Brandon Grotesque;
  }
  .h2.wp-block-heading {
    font-family: Brandon Grotesque;
  }
  
  @include breakpoint-between(320, 767) {
    .community__grid {
      width: 95%;
      .wp-block-column {
        width: 100%;
      }
    }
  }
  @include breakpoint-between(768, 1023) {
    .community__grid {
      width: 95%;
      .wp-block-column {
        width: 25%;
      }
    }
  }
  @include breakpoint-min('xl') {
    .wp-block-heading {
      line-height: 1.2 !important;//overriding the inline css
    }
  }

  .community {
    &__para {
      padding: 80px 0 40px;
      color: var(--GDF-Neutral---Dark-Grey, $text-color);
      font-family: Brandon Grotesque;
      font-size: $font-size-xl;
      max-width: 1080px;
      margin: 0 auto;
      width: 90%;
    }
    &__title {
      padding: 80px 0 40px;
      font-size: $font-size-5xl;
      max-width: 1080px;
      margin: 0 auto;
      width: 90%;
    }
    &__card {
      max-width: 252px;
      width: 100%;
  
      h2 {
        font-size: $font-size-3xl;
        line-height: 25px;
        min-height: 50px;
      }
  
      &__img {
        height: 252px;
        max-height: 252px;
      }
  
      .wp-block-heading {
        color: var(--GDF-Neutral---Dark-Grey, $text-color);
        text-align: center;
        font-family: Brandon Grotesque;
        font-size: 25px;
        font-weight: $font-weight-medium;
        line-height: 25px;
      }
  
      .wp-block-button {
        text-align: center;
  
        a {
          text-decoration: none;
        }
      }
    }
    &__grid {
      display: flex;
      max-width: 1080px;
      margin: 0 auto;
      align-items: flex-start;
      gap: 24px;
      justify-content: center;
      padding-bottom: 56px;
      width: 90%;
      flex-wrap: wrap;
    }
  }
  .community__grid {
    @include breakpoint-max-custom(480) {
      padding-bottom: 20px;
    }
  }
  .community__card h2 {
    @include breakpoint-max-custom(480) {
      min-height: unset;
    }
  }
  .community__card {
    @include breakpoint-max('sm') {
      max-width: 100%;
    }
    @include breakpoint-max-custom(1250) {
      max-width: 100%;
    }
  }
  .community__title {
    @include breakpoint-max('sm') {
      padding: 30px 0 20px;
    }
  }

  .community__grid__parent,
  .community_hospital {
    @include breakpoint-max('md') {
    width: 90%;
    margin: 0 auto;
  }
}
.community_hospital .community__grid h2 {
  @include breakpoint-max-custom(580) {
    min-height: unset;
  }
}
.community__card__img img {
  @include breakpoint-max('md') {
    height: auto;
    max-width: 100%;
  }
}
.community__title {
  @include breakpoint-max('md') {
    padding: 30px 0;
    // used important beacuse of override the inline cms css
    font-size: $font-size-2md !important;
  }
}
.community__para {
  @include breakpoint-max('md') {
    padding: 40px 0;
  }
  @include breakpoint-max(1200) {
    padding: 40px 0 40px;
  }
}
.community__card .wp-block-heading {
  @include breakpoint-max('md') {
    // used important beacuse of override the inline cms css
    font-size: $font-size-lg !important;
  }
}
.community__card__img {
  @include breakpoint-max('sm') {
    height: auto;
    text-align: center;
  }
}
}
.mission-pillars {
  .mission-pillar-card-section {
      .inner-card-container {
        .getinvoled__card {
          @include breakpoint-between($breakpoint-xs, $breakpoint-sm-md) {
            min-height: auto;
          }
      }
    }
  }
}
.leadership {
  @include breakpoint-between($breakpoint-xs, $breakpoint-sm-md) {
  .bod-row {
    .bod-col {
      padding-bottom: 40px;
      h6 {
          font-size: $font-size-lg;
          margin: 10px 0;
        }
      }
    }
  }
}