@import '/src/style/variables';
@import '/src/style/mixins';
@import '/src//style/breakpoints';

.photo-carousel {
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin: 40px auto 60px;
  padding: 0 16px;
}
.slider-container {
  max-width: 1080px;
  width: 100%;
  margin: 0 auto;
}
.carousel-heading-container {
  max-width: 1080px;
  width: 100%;
  margin: 0 auto;
  text-align: center;
  padding-top: 40px;
  font-size: $font-size-3xl;
  font-weight: 450;
  h2#carousel-heading {
    margin-bottom: 0px;
    font-weight: 450;
  }
}
.carousel-desc-container #carousel-desc {
  margin: 0 auto;
  text-align: center;
  max-width: 1080px;
  font-size: $font-size-xl;
  font-weight: $font-weight-normal-medium;
  line-height: 33.6px;
}
.slick-disabled .svg-nav-icon path {
  fill: $disabled-gray;
}
.slick-next {
  right: 0px;
}
.slick-prev {
  left: 0px;
}
.slid-image-desc {
  margin-top: 16px;
}
.custom-thumbnail-slider .slick-arrow,
.custom-thumbnail-slider .slick-dots  {
  display: none;
}
.slick-next:before, .slick-prev::before {
  display: none;
}
.text-overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background: rgba(0, 0, 0, 0.60);
  color: $color-white;
  padding: 10px;
  font-size: $font-size-base;
  font-weight: $font-weight-normal-medium;
  line-height: 22.4px;
  text-align: left;
}
.text-image-container {
  position: relative;
  font-size: $font-size-xl;
  font-weight: $font-weight-normal-medium;
  overflow: hidden;
}
.text-overlay p {
  margin: 5px 0;
}
.slid-image-desc {
  align-items: center;
  display: flex;
  justify-content: space-between;
  font-size: $font-size-sm;
  font-weight: $font-weight-light-medium;
  color: $text-color;
}
.carousel-desc-container {
  margin-bottom: 40px;
}
.svg-icon {
  fill: $color-black;
  width: 24px;
  height: 24px;
}
.svg-nav-icon {
  fill: $color-white;
}
.slick-prev {
  z-index: 10;
}
.custom-main-slider .slick-arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  @include breakpoint-between($breakpoint-xs, $breakpoint-xs-sm) {
    top: 101px;
  }
  @include breakpoint-between($breakpoint-sm, $breakpoint-custom-sm) {
    top: 101px;
  }
  @include breakpoint-between($breakpoint-custom-sm-md, $breakpoint-sm-md) {
    top: 174.5px;
  }
  @include breakpoint-between($breakpoint-md, $breakpoint-md-lg) {
    top: 174.5px;
  }
  @include breakpoint-between($breakpoint-lg, $breakpoint-lg-xl) {
    top: 237px;
  }
  @include breakpoint-min('xl') {
    top: 275px;
  }
}
.custom-main-slider {
  width: 100%;
  margin: 0 auto;
}
.custom-thumbnail-slider .slick-current img:focus,
.custom-thumbnail-slider .slick-current img:focus-visible {
  outline: none;
  border: none;
  box-shadow: none;
}
*:focus,
*:focus-visible {
  outline: none;
  // border: none;
  box-shadow: none;
}
.custom-thumbnail-slider .slick-current img {
  outline: 3px solid $color-orange;
  outline-offset: -3px;
}
.custom-thumbnail-slider .slick-track {
  transition: transform 0.3s ease;
}
.custom-thumbnail-slider .slick-slide {
  position: relative;
  width: 100%;
  transition: opacity 0.2s ease;
}
.slick-slide .thumbnail {
  cursor: pointer;
  transition: transform 0.1s ease, opacity 0.1s ease;
  border: 2px solid transparent;
}
.photo-carousel {
  width: 100%;
  max-width: 1080px;
  margin: 0 auto;
  overflow: hidden;
}
.slick-slide img {
  width: 100%;
  height: 100%;
  max-width: 100%;
  object-fit: cover;
}
.slick-arrow {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.thumbnail-slider {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
}
.thumbnail-slider img {
  width: 200px;
  height: 120px;
  object-fit: cover;
  border-radius: 5px;
  cursor: pointer;
  transition: transform 0.3s ease-in-out;
}
.text-image-container {
  height: 349px;
}
.custom-main-slider {
  max-width: 976px;
}
.custom-thumbnail-slider .slick-slide {
  height: 120px;
  width: 200px;
  overflow: hidden;
}
.custom-thumbnail-slider .slick-slide img{
  height: 120px;
  width: 200px;
}
.custom-main-slider .slick-slide img{
  width: 100%;
}
.custom-thumbnail-slider {
  width: 100%;
  margin-top: 24px;
  margin-bottom: 40px;
}
.custom-thumbnail-slider {
  .slick-slide {
    padding: 0 5px;
    box-sizing: border-box;
    img {
      width: 100%;
      display: block;
      object-fit: cover;
    }
  }
  .slick-list {
    margin: 0 -5px;
  }
}
.scholarships, .events {
  .wp-block-button.btn-orange {
    a {
      font-family: 'Brandon Grotesque black';
    }
  }
}
.talent-show {
  .slid-image-desc {
    font-family: 'Brandon Grotesque';
  }
}
@include breakpoint-custom-min-max(1025, 1439) {
  .photo-carousel { 
    padding: 0 24px;
  }
  .carousel-heading-container {
    width: calc(100% - 360px);
    margin: 0 auto;
    text-align: center;
    padding-top: 40px;
    font-weight: 450;
    #carousel-heading {
      font-size: 42px !important; //styles.scss 1413 line remove imp
    }
  }
  .carousel-desc-container {
    margin-bottom: 40px;
    width: calc(100% - 360px);
    text-align: center;
  }
  .custom-main-slider {
    max-width: 976px;
  }
  .slick-prev {
    left: -13px;
  }
  .slick-next {
    right: -13px;
  }
  .custom-thumbnail-slider {
    width: 100%;
    margin-top: 24px;
    margin-bottom: 80px;
    padding: 0 5px;
  }
  .custom-thumbnail-slider .slick-slide {
    height: 120px;
    width: 200px;
    overflow: hidden;
  }
  .custom-thumbnail-slider .slick-slide img{
    height: 120px;
    width: 200px;
    width: 100%;
  }
  .photo-carousel, .custom-thumbnail-slider {
    padding: 0;
  }
}
@include breakpoint-max-custom(1024) {
  .slider-container {
    margin: 0 auto;
    width: 100%;
  }
  .carousel-heading-container {
    width: calc(100% - 48px);
    margin: 0 auto;
    text-align: center;
    padding-top: 40px;
    line-height: 36px;
    #carousel-heading {
      font-size: $font-size-3xl;
    }
  }
  .carousel-desc-container {
    text-align: center;
    width: calc(100% - 48px);
    font-weight: $font-weight-normal-medium;
    line-height: 25.2px;
    margin-bottom: 40px;
  }
  .custom-thumbnail-slider {
    width: 100%;
    overflow: hidden;
    padding: 0;
  }
  .custom-thumbnail-slider .slick-slide {
    height: 120px;
    width: 200px;
    overflow: hidden;
  }
  .custom-thumbnail-slider .slick-slide img{
    height: 120px;
    width: 200px;
    width: 100%;
  }
  .svg-icon {
    fill: $color-black;
    width: 24px;
    height: 24px;
  }
  .slick-arrow {
    height: 24px;
    width: 24px;
  }
}
@include breakpoint-max-custom(820) {
  #carousel-desc{
    text-align: center;
    width: 100%;
    max-width: 720px;
    font-size: $font-size-md;
    font-weight: $font-weight-normal-medium;
    line-height: 25.2px;
  }
  .custom-thumbnail-slider {
      overflow: hidden;
  }
  .carousel-heading-container {
    width: 100%;
    max-width: 720px;
    margin: 0 auto;
    text-align: center;
    padding-top: 40px;
  }
  .carousel-desc-container {
    text-align: center;
    width: 100%;
    max-width: 720px;
    font-size: $font-size-md;
    font-weight: $font-weight-normal-medium;
    line-height: 25.2px;
    padding: 0;
    margin: 0 auto;
  }
  .custom-thumbnail-slider .slick-slide {
    height: 120px;
    width: 200px;
    overflow: hidden;
  }
  .custom-thumbnail-slider .slick-slide img{
    height: 120px;
    width: 200px !important;
    width: 100%;
  }
  .custom-main-slide .text-image-container img.slick-slide-image {
    height: 202px;
  }
}
@include breakpoint-custom-min-max(391, 479) {
  #carousel-desc{
    text-align: center;
    width: 100%;
    max-width: 720px;
    font-size: $font-size-md;
    font-weight: $font-weight-normal-medium;
    line-height: 25.2px;
    padding: 0;
  }
  .slider-container {
    width: 100%;
    margin: 0 auto;
  }
  .carousel-heading-container {
    margin-top: 40px;
  }
  .carousel-heading-container, .carousel-desc-container {
    width: 100%;
    padding: 0 16px;
  }
  .custom-thumbnail-slider {
    padding: 0;
    overflow: hidden;
  }
  .custom-main-slider {
    width: 100%;
    padding: 0px;
  }
  .text-image-container {
    height: 202px;
  }
  .custom-main-slider .slick-slide img {
    height: 202px;
    width: 100%;
  }
  .custom-main-slide .text-image-container img.slick-slide-image {
    height: 202px;
  }
  .custom-thumbnail-slider .slick-slide {
    height: 84px;
    overflow: hidden;
  }
  .custom-thumbnail-slider .slick-slide img{
    height: 84px;
    width: 100%;
  }
  .custom-thumbnail-slider .slick-current img {
    outline: 3px solid $color-orange;
    outline-offset: -3px;
  }
  .text-overlay {
    height: 33%;
  }
  .text-overlay p {
    margin: 0px 0;
  }
  .svg-nav-icon {
    fill: $color-white;
  }
}
@include breakpoint-max-custom(391) {
  .slick-prev {
    z-index: 5;
  }
  .header .header-button {
    width: 85px;
  }
  .talent-show .carousel-heading-container {
    width: calc(100% - 32px);
    margin: 0 auto;
    text-align: center;
    line-height: 36px;
    margin-bottom: 15px;
    padding-top: 40px;
    #carousel-heading {
      font-size: 32px !important; // CSS conflict with style line: 5100
      margin-top: 0;
    }
  }
  .carousel-desc-container {
    width: calc(100% - 32px);
    margin: 0 auto;
    text-align: center;
    line-height: 25.2px;
  }
  .text-overlay p {
    margin: 0;
  }
  .custom-main-slider {
    width: 100%;
    margin: 0 auto;
  }
  .text-image-container {
    height: 202px;
  }
  .custom-thumbnail-slider .slick-slide {
    height: 84px;
    width: auto;
    overflow: hidden;
  }
  .custom-thumbnail-slider .slick-slide img{
    height: 84px;
    width: 140px;
    width: 100%;
  }
  .slick-arrow svg {
    fill: $color-white;
  }
}
@include breakpoint-max-custom(321) {
  .photo-carousel {
    padding: 0 16px;
  }
  .carousel-heading-container {
      width: calc(100% - 32px);
      margin: 0 auto;
      text-align: center;
      line-height: 36px;
      margin-bottom: 15px;
  }
}
@include breakpoint-min('xs'){
  .talent-show .carousel-heading-container {
    #carousel-heading {
      color: $blue-bg !important;
      font-weight: 450 !important;
    }
  }
  .category .carousel-desc-container {
    margin-bottom: 30px;
    #carousel-desc {
      line-height: 25.2px;
      color: $text-color;
      font-size: $font-size-md;
    }
  }
  .custom-main-slider .slick-slide img{
    min-width: 139px;
  }
  .talent-show, .talent-show-backup {
    .btn-orange a {
      font-size: $font-size-sm;
    }
  }
}
@include breakpoint-custom-min(360) {
  .custom-thumbnail-slider {
    .slick-slide {
      padding: 0 7px;
    }
    .slick-list {
      margin: 0 -6px;
    }
  }
}
@include breakpoint-custom-min(375) {
  .custom-thumbnail-slider {
    .slick-list {
      margin: 0 -3px;
    }
    .slick-slide {
      padding: 0px 5px;
    }
  }
}
@include breakpoint-min('sm') {
  .custom-thumbnail-slider {
    .slick-slide {
      padding: 0 5px;
      box-sizing: border-box;
    }
    .slick-list {
      margin: 0 -3px;
    }
  }
}
@include breakpoint-custom-min-max(480, 767) {
  .talent-show .carousel-heading-container {
    width: calc(100% - 32px);
    margin: 0 auto;
    text-align: center;
    line-height: 36px;
    margin-bottom: 15px;
  }
  .carousel-desc-container {
    width: calc(100% - 32px);
    margin: 0 auto;
    text-align: center;
  }
  .custom-thumbnail-slider .slick-slide {
    height: 105px;
    overflow: hidden;
  }
  .custom-thumbnail-slider .slick-slide img{
    height: 105px;
    overflow: hidden;
  }
  .custom-thumbnail-slider .slick-slide img{
    height: 105px;
    width: 100%;
    overflow: hidden;
  }
  .text-image-container {
    height: 349px;
  }
  .custom-main-slider .slick-slide img{
    height: 349px;
    width: 100%;
  }
}
@include breakpoint-min('md'){
  .photo-carousel {
    padding: 0 24px;
  }
  .custom-main-slider {
    width: 100%;
    padding: 0 48px;
  }
  .slider-container {
    margin: 0 auto;
  }
  .carousel-heading-container {
    width: calc(100% - 48px);
    margin: 0 auto;
    text-align: center;
    line-height: 36px;
    padding-bottom: 20px;
    #carousel-heading {
      font-size: $font-size-3xl;
    }
  }
  .carousel-desc-container {
    width: 100%;
    width: calc(100% - 48px);
    font-weight: $font-weight-normal-medium;
    text-align: center;
    margin: 0 auto;
    #carousel-desc{
      font-size: 18px !important; // style <p> line: 2928
    }
  }
  .custom-main-slider .slick-slide img{
    width: 100%;
  }
  .custom-thumbnail-slider {
    width: 100%;
    margin-top: 24px;
    margin-bottom: 80px;
    overflow: hidden;
    padding: 0;
  }
  .custom-thumbnail-slider .slick-slide {
    height: 120px;
    overflow: hidden;
  }
  .custom-thumbnail-slider .slick-slide img{
    height: 120px;
    width: 100%;
  }
  .custom-main-slide .text-image-container img.slick-slide-image {
    height: 202px;
  }
  .svg-nav-icon {
    fill: $color-blue;
  }
  .category .carousel-desc-container {
    margin-bottom: 40px;
  }
  .slick-next {
    right: -0px;
  }
  .slick-prev {
    left: -0px;
  }
  .text-image-container {
    height: 349px;
  }
  .custom-thumbnail-slider .slick-current img {
    outline: 4px solid $color-orange;
    outline-offset: -4px;
  }
  .custom-main-slider .slick-slide img{
    height: 349px;
    width: 100%;
  }
  .text-overlay {
    padding: 16px;
    font-size: $font-size-xl;
    line-height: 33.6px;
  }
  .custom-thumbnail-slider {
    .slick-slide {
      padding: 0 4px;
      box-sizing: border-box;
      img {
        width: 100%;
        display: block;
      }
    }
    .slick-list {
      margin: 0 -4px;
    }
  }
  .talent-show .button-wrapper {
    display: none;
  }
}
@include breakpoint-min('lg'){
  .svg-nav-icon:hover {
    fill: $color-orange;
  }
  .slick-disabled svg {
    cursor: default;
  }
  .slick-disabled .svg-nav-icon path {
    fill: $disabled-gray;
  }
  .text-image-container {
    height: 474px;
  }
  .custom-main-slider {
    padding: 0 48px;
  }
  .custom-main-slider .slick-slide img{
    height: 474px;
    width: 100%;
  }
  .custom-thumbnail-slider {
    .slick-slide {
      padding: 0 9px;
      box-sizing: border-box;
    }
    .slick-list {
      margin: 0 -9px;
    }
  }
  .text-overlay {
    padding: 24px;
  }
}
@include breakpoint-min('xl'){
  .category .carousel-desc-container {
    #carousel-desc {
      line-height: 33.6px;
      font-size: $font-size-xl;
    }
  }
  .photo-carousel {
    padding: 0;
    width: calc(100% - 360px);
  }
  .slider-container, .custom-main-slider {
    max-width: 100%;
  }
  .custom-main-slider {
    padding: 0 48px;
  }
  .text-image-container {
    height: 550px;
  }
  .carousel-heading-container #carousel-heading {
    font-size: $font-size-5xl;
    line-height: 46px;
  }
  .custom-main-slider .slick-slide img{
    height: 550px;
    width: 100%;
  }
  .custom-thumbnail-slider .slick-current img {
    outline: 5px solid $color-orange;
    outline-offset: -5px;
  }
  .text-overlay {
    padding: 24px;
  }
  .custom-thumbnail-slider .slick-list {
    margin: 0 7px;
  }
}
@media (hover: none) {
  .slick-disabled .svg-nav-icon path {
    fill: $disabled-gray;
  }
}

.img-name, .img-song {
  line-height: 140%;
  .label {
    font-weight: $font-weight-normal-medium;
    font-family: 'Brandon Grotesque';
  }
  .description {
    font-weight: $font-weight-medium;
    font-family: 'Brandon Grotesque';
  }
}
@include breakpoint-between($breakpoint-sm, $breakpoint-sm-md) {
  .img-name, .img-song {
    font-size: 16px;
  }
}

@include breakpoint-custom-min($breakpoint-sm-md) {
  .talent-show {
    .text-overlay {
      .img-name, .img-song {
        font-size: $font-size-base;
      }
    }
  }
}