
@import './_variables.scss';
@import './_mixins.scss';
@import './breakpoints.scss';

.hero-section {
    .wp-block-cover__inner-container {
        @include breakpoint-between($breakpoint-xs, $breakpoint-xs-sm) {
            max-width: 120px;
            margin-left: 10px;
            display: flex;
            width: 100%;
            align-items: baseline;
            h4.wp-block-heading {
                width: 100px;
                line-height: 18px;
                font-size: $font-size-base;
                font-weight: $font-weight-normal-bold;
                text-align: left;
                font-family: 'Brandon Grotesque bold';
                margin-left: 0px;
                padding-left: 0px;
                padding-right: 0px;
            }
            h1.wp-block-heading {
                width: 100px;
                line-height: 18px;
                font-size: $font-size-base;
                font-weight: $font-weight-normal-bold;
                text-align: left;
                font-family: 'Brandon Grotesque bold';
                margin-left: 0px;
                padding-left: 0px;
                padding-right: 0px;
            }
        }
        @include breakpoint-between($breakpoint-sm, $breakpoint-sm-md) {
            max-width: 146px;
            margin-left: 12px;
            display: flex;
            width: 100%;
            align-items: baseline;
            h4.wp-block-heading {
                width: 122px;
                min-width: 122px;
                line-height: 22px;
                font-size: $font-size-lg;
                font-weight: $font-weight-normal-bold;
                text-align: left;
                font-family: 'Brandon Grotesque bold';
                margin-left: 0px;
                padding-left: 0px;
                padding-right: 0px;
            }
            h1.wp-block-heading {
                width: 122px;
                min-width: 122px;
                line-height: 22px;
                font-size: $font-size-lg;
                font-weight: $font-weight-normal-bold;
                text-align: left;
                font-family: 'Brandon Grotesque bold';
                margin-left: 0px;
                padding-left: 0px;
                padding-right: 0px;
            }
        }
        @include breakpoint-between($breakpoint-md, $breakpoint-md-lg) {
            max-width: 288px;
            margin-left: 30px;
            display: flex;
            width: 100%;
            align-items: baseline;
            h4.wp-block-heading {
                width: 228px;
                min-width: 228px;
                line-height: 36px;
                font-size: $font-size-3xl;
                font-weight: $font-weight-normal-bold;
                text-align: left;
                font-family: 'Brandon Grotesque bold';
                margin-left: 0px;
                padding-left: 0px;
                padding-right: 0px;
            }
            h1.wp-block-heading {
                width: 228px;
                min-width: 228px;
                line-height: 36px;
                font-size: $font-size-3xl;
                font-weight: $font-weight-normal-bold;
                text-align: left;
                font-family: 'Brandon Grotesque bold';
                margin-left: 0px;
                padding-left: 0px;
                padding-right: 0px;
            }
        }
        @include breakpoint-between($breakpoint-lg, $breakpoint-xl) {
            max-width: 384px;
            margin-left: 40px;
            display: flex;
            width: 100%;
            align-items: baseline;
            h4.wp-block-heading {
                width: 304px;
                min-width: 304px;
                line-height: 36px;
                font-size: $font-size-3xl;
                font-weight: $font-weight-normal-bold;
                text-align: left;
                font-family: 'Brandon Grotesque bold';
                margin-left: 0px;
                padding-left: 0px;
                padding-right: 0px;
            }
            h1.wp-block-heading {
                width: 304px;
                min-width: 304px;
                line-height: 36px;
                font-size: $font-size-3xl;
                font-weight: $font-weight-normal-bold;
                text-align: left;
                font-family: 'Brandon Grotesque bold';
                margin-left: 0px;
                padding-left: 0px;
                padding-right: 0px;
            }
        }
        @include breakpoint-min('xl') {
            max-width: 540px;
            margin-left: 50px;
            display: flex;
            width: 100%;
            align-items: baseline;
            h4.wp-block-heading {
                width: 440px;
                min-width: 440px;
                line-height: 46px;
                font-size: $font-size-5xl;
                font-weight: $font-weight-normal-bold;
                text-align: left;
                font-family: 'Brandon Grotesque bold';
            }
            h1.wp-block-heading {
                width: 440px;
                min-width: 440px;
                line-height: 46px;
                font-size: $font-size-5xl;
                font-weight: $font-weight-normal-bold;
                text-align: left;
                font-family: 'Brandon Grotesque bold';
                margin-left: 0px;
                padding-left: 0px;
                padding-right: 0px;
            }
        }
    }
}