@import './_variables.scss';
@import './breakpoints.scss';

//community page

.hero-section {
  .wp-block-cover__inner-container {
    max-width: unset;
  }
}

figure {
  margin: 0 0;

  figcaption {
    background: $color-black;
    color: $color-white;
    padding: 6px 0.2rem;
    font-size: $font-size-xs;
    font-family: Brandon Grotesque;
  }
}
.community {
  &__para {
    padding: 80px 0 40px;
    color: var(--GDF-Neutral---Dark-Grey, #222);
    font-family: Brandon Grotesque;
    font-size: $font-size-xl;
    max-width: 1080px;
    margin: 0 auto;
    width: 90%;
  }

  &__title {
    padding: 80px 0 40px;
    font-size: $font-size-5xl;
    max-width: 1080px;
    margin: 0 auto;
    width: 90%;
  }

  &__card {
    max-width: 252px;
    width: 100%;

    h2 {
      font-size: $font-size-3xl;
      line-height: 25px;
      min-height: 50px;
    }

    &__img {
      height: 252px;
      max-height: 252px;
    }
    @include breakpoint-min('md') {
      &__img {
          height: 168px;
          max-height: 168px;
          img {
            max-height: 168px;
          }
      }
    }
    @include breakpoint-min('lg') {
      &__img {
          height: 252px;
          max-height: 252px;
          img {
            max-height: 252px;
          }
      }
    }

    .wp-block-heading {
      color: var(--GDF-Neutral---Dark-Grey, #222);
      text-align: center;
      font-family: Brandon Grotesque;
      font-size: 25px;
      font-weight: $font-weight-medium;
      line-height: 25px;
    }

    .wp-block-button {
      text-align: center;

      a {
        text-decoration: none;
        font-family: Brandon Grotesque
      }
    }
  }

  &__grid {
    display: flex !important;
    max-width: 1080px;
    margin: 0 auto;
    align-items: flex-start;
    gap: 24px;
    justify-content: center;
    padding-bottom: 56px;
    width: 90%;
    flex-wrap: wrap;
  }
}

.financial-section-body {
  max-width: 1080px;
  margin: 0 auto;
  width: 90%;
}

/* Mission & Pillars css started */
.missionImageBlock,
.missionhd,
.missionpanel {
  max-width: 1080px;
  margin: 0 auto;
  width: 90%;
}

.missionImageBlock {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 24px;
  padding-top: 80px;
  padding-bottom: 40px;
}

.missionImageBlock figure {
  margin-bottom: 0;
}

.missionImageBlock img {
  width: 100%;
}

.missionhd {
  font-size: $font-size-xl !important;
  margin-bottom: 40px !important;
}

.missionpanel {
  display: flex;
  gap: 24px;
  padding: 40px 0 80px;
  justify-content: center;

  figure {
    margin: 0 0 !important;
  }
}

.missionblock {
  background: $color-blue-light;
  color: $color-white;
  width: 25%;
  padding: 40px 20px;
  text-align: center;
  max-width: 258px;
}

.missionblock h2 {
  font-size: 1.75rem !important;
  color: #fff !important;
  line-height: 22px;
}

.missionblock p {
  font-size: $font-size-xl;
  line-height: 33.6px;
}

.captioned-images {
  position: relative;
  figure {
    position: relative;
    width: 100%;
    margin: 0 0;

    figcaption {
      position: absolute;
      width: 100%;
      left: 0;
      bottom: 0;
      background: $color-black;
      color: $color-white;
      padding: 0.2rem;
      font-size: $font-size-md;
    }
  }

  img {
    width: 100%;
    object-fit: cover;
  }
}

/* Mission & Pillars css end */
/* Leadership css started */
.leadershipbanner {
  margin-bottom: 60px;
}

.leadershipbanner h2 {
  color: $color-white !important;
  padding: 0 40px !important;
}

.teampanel {
  max-width: 1320px;
  margin: 0 auto;
  justify-content: center;
  display: flex;
  gap: 24px;
  padding: 20px 0;
  flex-wrap: wrap;
}

.teamblock {
  width: 30%;
  max-width: 252px;
}

.teamblock img {
  max-width: 100%;
}

.teamblock h6 {
  font-size: $font-size-3xl !important;
  font-style: normal;
  font-weight: 900 !important;
  line-height: 36px;
  color: $text-color !important;
  text-transform: none;
  margin: 0;
  font-family: Brandon Grotesque bold !important;
}

.teamblock p {
  font-size: $font-size-xl;
  font-style: normal;
  font-weight: $font-weight-normal-medium;
  text-align: center;
  padding: 0 20px;
  line-height: 1.4;
}

/* Leadership css end */

//who we are

a,
button,
btn {
  transition: all ease-in-out 0.25s;
}

header {
  &.wp-block-cover {
    p.has-text-align-center {
      text-align: center;
      font-weight: 100;

      strong {
        mark {
          color: $color-white;
        }
      }
    }

    .wp-block-button {
      &.btn-hero-banner {
        display: flex;
        justify-content: center;
        margin: 25px 0 20px;

        .wp-element-button {
          background: $blue-bg;
          color: $color-white;
          text-decoration: none;
          padding: 15px 25px;
          font-size: $font-size-sm;
          text-transform: uppercase;
          letter-spacing: 1.2px;

          &:hover {
            background: #111638;
          }
        }
      }
    }
  }

  .wp-block-cover {
    &__inner-container {
      max-width: unset;
      width: 100%;
      text-align: left;
      justify-content: flex-start;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }
  }
}
.category {
  .wp-block-cover {
    h4 {
      color: $color-white;
      font-family: Brandon Grotesque bold;
      text-align: center;
    }
  }
}

.wp-block-cover {
  h4 {
    color: $color-white;
    font-size: 4rem;
    font-weight: 600;
    font-family: Brandon Grotesque;
    text-align: center;
  }

  p.has-text-align-center {
    text-align: center;
    font-weight: 100;

    strong {
      mark {
        color: $color-white;
      }
    }
  }

  .wp-block-button {
    &.btn-hero-banner {
      display: flex;
      justify-content: center;
      margin: 25px 0 20px;

      .wp-element-button {
        background: $blue-bg;
        color: $color-white;
        text-decoration: none;
        padding: 15px 25px;
        font-size: $font-size-sm;
        text-transform: uppercase;
        letter-spacing: 1.2px;

        &:hover {
          background: #111638;
        }
      }
    }
  }
}

.left-image-text-section {
  display: flex;
  max-width: 1080px;
  margin: 0px auto;
  flex-direction: column;
}

.left-image-text {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 30px;

  margin: 50px auto;
  max-width: 1080px;
  gap: 24px;

  figure {
    &.wp-block-media-text__media {
      img {
        width: 100%;
      }
    }
  }
}


//financial page
.financial-section-body {
  display: flex;
  flex-direction: column;
  padding-top: 80px;

  h1 {
    margin-top: 32px;
    font-weight: $font-weight-strong;
    font-size: $font-size-5xl;
    line-height: 46px;
    margin: 0 0 20px;
  }

  ul {
    margin-top: 0px;
    margin-left: 32px;
    padding-bottom: 20px;
  }

  li {
    list-style-type: disc;
    text-decoration: underline;
    font-size: 22px !important;
  }

  mark {
    color: $color-black !important;
  }
}

.mentorship__page .mentorship__text__block,
#mentorship__video__title {
  max-width: 1080px;
  margin: 0 auto;
  width: 90%;
}

#mentorship__video__title {
  padding-top: 80px !important;
}

#mentorship__text__block__titile {
  margin-top: 40px;
}

// mentorship
.mentorship {
  &__para {
    max-width: 1080px;
    margin: 0 auto;
    width: 90%;
    padding-top: 80px;
  }

  &__page {
    color: var(--GDF-Neutral---Dark-Grey, #222) !important;
    font-family: Brandon Grotesque;
    font-size: $font-size-xl !important;
    font-style: normal;
    font-weight: 420 !important;
    line-height: 140%;
  }

  &__text__wrapper {
    padding-bottom: 40px;
  }

  &__text__img {
    img {
      max-width: 528px !important;
      max-height: 352px !important;
    }
  }

  &__video {
    max-width: 1080px;
    margin: 0 auto;
    width: 90%;

    &__card {
      width: 344px;
      height: 257px;
      padding-top: 16px;
      margin-bottom: 140px !important;
      text-align: center;

      &__wrapper {
        display: grid !important;
        grid-template-columns: 1fr 1fr 1fr;
        margin-top: 20px;
      }
    }

    #mentorship__video__title strong {
      color: var(--GDF-Neutral---Dark-Grey, #222);

      /* GDF H2 */
      font-family: Brandon Grotesque;
      font-size: $font-size-5xl;
      font-style: normal;
      font-weight: bold;
      line-height: 46px;
      /* 109.524% */
    }
  }

  &__text__para {
    h5 {
      color: var(--GDF-Neutral---Dark-Grey, #222);
      text-align: center;
      font-family: Brandon Grotesque;
      font-size: $font-size-3xl;
      font-style: normal;
      font-weight: $font-weight-medium-bold;
      line-height: 36px;
    }

    h6 {
      color: var(--GDF-Neutral---Dark-Grey, #222) !important;
      text-align: center;
      font-family: Brandon Grotesque !important;
      font-size: $font-size-xl !important;
      font-style: normal !important;
      font-weight: 420 !important;
      line-height: 140%;
      padding-top: 16px !important;
    }
  }
}

// mentorship ended

/**Sponsorship css started **/
.sponsortext {
  max-width: 1080px;
  margin: 0 auto;
  padding-top: 40px;
}

.sponsortext a {
  color: $color-blue;
  text-decoration: underline;
}

/**Sponsorship css end **/

/**marthon css started **/
.marthontext {
  max-width: 1080px;
  margin: 0 auto;
  padding-top: 40px;
}

.marthontext a {
  color: $color-blue;
  text-decoration: underline;
}

.marthonpanel {
  max-width: 1080px;
  margin: 0 auto;
  display: flex;
  gap: 20px;
  margin-top: 40px;
}

.marthonpanel img {
  max-width: 100%;
}

/**marthon css end **/

/**donors css started **/
.donortext {
  max-width: 1080px;
  margin: 0 auto;
  padding-top: 40px;
}

.donortext a {
  color: $color-blue;
  text-decoration: underline;
}

.donorspanel {
  max-width: 1080px;
  margin: 0 auto;
  display: flex;
  gap: 20px;
  margin-top: 40px;
}

.donorspanel img {
  max-width: 100%;
}

/**donors css end **/
/* Grants css started */
.godcontainer {
  max-width: 1080px;
  margin: 0 auto;
}

.grantspanel {
  display: flex;
  justify-content: center;
  gap: 24px;
}

.yellowgrantblock {
  max-width: 100%;
  background: $text-color;
  display: flex;
  gap: 24px;
  justify-content: center;
  padding: 80px 0;
  align-items: center;
  margin-bottom: 40px;
}

.yellowgrantblock .yellowgrant {
  max-width: 528px;
}

.grantsblock {
  padding-top: 80px;
}

.grantsblock p {
  padding-bottom: 20px;
  margin: 0;
}

.grantspanel img {
  max-width: 100%;
}

.grantspanel figure {
  margin: 0;
}

.yellowgrantblock .yellowgrant img {
  max-width: 100%;
}

.grantsblock h1 {
  font-size: 60px;
  font-family: Brandon Grotesque bold !important;
  padding-top: 60px;
  margin-top: 0;
  margin-bottom: 20px;
}

.grantsblock h2 {
  font-size: $font-size-5xl;
  font-family: Brandon Grotesque bold !important;
  margin: 40px 0;
}

.grantspanel {
  margin-bottom: 80px;
}

.grantshd {
  margin-bottom: 40px !important;
}

.partnershd {
  color: $color-orange !important;
  font-size: $font-size-lg !important;
  margin-bottom: 40px !important;
  text-align: center;
}

.partnertext {
  font-size: $font-size-3xl !important;
  font-family: Brandon Grotesque bold !important;
  color: $text-color !important;
  text-align: center;
  margin-bottom: 20px;
}

.partnername {
  font-size: $font-size-xl !important;
  text-align: center;
  margin-bottom: 80px;
}

/* Grants css end */

// our work css start

.our-work-title {
  margin-bottom: 20px !important;
  font-size: $font-size-5xl !important;
  font-family: Brandon Grotesque bold !important;
}

.our-work-list {
  li {
    list-style-type: disc !important;
  }
}

// our work css end

.primary-button {
  padding: 1rem;
  width: min-content;
  background-color: $color-orange;
  padding-left: 2.2rem;
  padding-right: 2.2rem;

  a {
    text-decoration: none;
    color: $text-color;
    font-size: $font-size-lg;
    font-weight: 700;
    font-family: Brandon Grotesque bold;
  }
}

.our-work-list {
  margin-bottom: 20px;
}

.season-of-giving-content {
  margin-top: 2rem;
}

.season-of-giving-content-img {
  overflow: hidden;
  margin-left: 6rem !important;
  margin-right: 6rem !important;

  img {
    height: auto;
  }
}

.season-of-giving-content-media {
  display: flex;
  flex-direction: row;
  padding-left: 6rem !important;
  padding-right: 6rem !important;
}

.community_hospital .community__grid h2 {
  min-height: 78px;
}

.hero-section.lg-text h4 {
  text-align: center;
}

.mission-pillar-card-section h2 {
  min-height: auto;
}

.god-heading.ex-dir .bod-row .bod-col {
  max-width: 260px !important;
  width: 100%;
}

.donate__page .left-section .text-group {
  align-items: center !important;
}

.blog-details .story-banner img {
  height: 351px !important;
  width: 100%;
  object-fit: cover !important;
}

.see-all-stories {
  .title {
    color: $gray-color;
    font-weight: $font-weight-medium-bold;
    grid-column: 1/-1;
  }
}
.carousle-card {
  position: relative;
  .caption {
    background: $color-black;
    color: $color-white;
    padding: 6px 0.2rem;
    font-size: 13px;
    position: absolute;
    bottom: 23px;
    width: 100%;
    &:empty {
      display: none;
    }
  }
}

.new-hero-banner {
  .wp-block-cover__inner-container {
    max-width: 100%;
  }
}

.new-hero-banner {
  img {
    @include breakpoint-max-custom(1300) {
      min-height: 350px;
  }
 }
}

.mentorship__video__card__wrapper {
  @include breakpoint-max-custom(1300) {
    display: grid;
    grid-template-columns: repeat(auto-fill, 344px);
    gap: 16px;
    justify-content: center;
  }
}

.landingPage.hero-section .carousel-inner .carousel-caption .btn.btn-orange {
  background: unset;
  height: 66.667px;
  padding: 10.667px 34.667px;
  justify-content: center;
  align-items: center;
  gap: 13.333px;
  font-size: $font-size-base;
  font-style: normal;
  font-weight: $font-weight-medium;
  line-height: 100%;
  background: $color-orange;
  color: $text-color;
  font-family: 'Brandon Grotesque black';

  @include breakpoint-max('xl') {
    height: 51.483px;
    padding: 8.237px 26.771px;
    gap: 10.297px;
  }
  @include breakpoint-max('lg') {
    height: 47.639px;
    padding: 7.622px 24.772px;
    gap: 9.528px;
  }
  @include breakpoint-max('md') {
    height: 35.729px;
    padding: 5.717px 18.579px;
    gap: 7.146px;
  }
}
section.landingPage.hero-section .carousel-indicators button.carousel-dot {
  @include breakpoint-max('md') {
    margin: 0 5px;
  }
}

@include breakpoint-max-custom(1200) {
  .financial-section-body {
    padding-top: 40px !important;
  }

  .grantsblock {
    padding: 40px 20px !important;
  }

  .getinvoled__columns {
    .getinvoled__card {
      padding: 0 !important;
    }
  }

  header {
    &.wp-block-cover {
      &__inner-container {
        max-width: 980px;
        padding: 0 30px;
      }

      p.has-text-align-center strong mark {
        color: $color-white;
        font-size: $font-size-base;
      }
    }

    .wp-block-cover {
      &__inner-container {
        padding: 0 30px;
      }

      .wp-block-button {
        &.btn-hero-banner {
          .wp-element-button {
            padding: 12px 15px;
            font-size: $font-size-xs;
          }
        }
      }
    }
  }

  .left-image-text {
    max-width: 980px;
    padding: 0 30px;
    margin-bottom: 0 !important;
  }
}

@include breakpoint-max-custom(1150) {
  .new-hero-banner {
    min-height: 270px !important;
  }

  header.wp-block-cover h4 {
    max-width: 66%;
  }

  .hero-section .wp-block-cover__inner-container {
    max-width: 37%;
    display: flex;
    justify-content: center;
    align-items: start;
    left: 0;
    margin: 0 auto;
  }
  .wp-block-cover h4 {
    text-align: center;
    width: 100%;
  }
}

@include breakpoint-max-custom(1100) {
  .blog-details-container {
    .blog-details {
      .story-banner {
        img {
          padding: 20px;
        }
      }
      h2 {
        padding: 0 20px !important;
      }
    }
  }

  .category-container {
    padding: 0 20px;
  }

  .getinvoled__text {
    padding: 0 20px;
  }

  .sponsorship-section {
    padding: 30px 30px 50px !important;
  }
}

@include breakpoint-max-custom(1090) {
  .mentorship__page .mentorship__text__block,
  #mentorship__video__title {
    width: 100%;
    padding: 0 20px;
  }

  .marthonpanel,
  .marthontext {
    width: 90%;
  }
  .donorspanel,
  .donortext {
    width: 90%;
  }

  .media-with-left-caption-section .media-with-left-caption-images .caption-image,
  .media-with-left-caption-section .media-with-right-caption-images .caption-image {
    max-width: 528px;
    height: auto;
    img {
      width: 100%;
    }
  }
}

@include breakpoint-max-custom(1037) {
  .getinvoled__columns {
    .getinvoled__card {
      max-width: 350px !important;
      padding: 0;
      padding: 1rem;
    }

    .wp-block-column {
      display: flex;
      justify-content: center;
    }

    justify-content: center;
    grid-template-columns: 1fr 1fr !important;
  }

  header .wp-block-cover__inner-container {
    padding: 0 10px;
  }
}

@include breakpoint-max-custom(1024) {
  .grantspanel {
    flex-wrap: wrap;
  }

  .grantspanel img {
    max-width: 100%;
  }

  .yellowgrantblock {
    flex-wrap: wrap;
  }

  .yellowgrantblock .yellowgrant {
    width: 100%;
  }

  .yellowgrantblock .yellowgrant img {
    max-width: 100%;
  }

  .landing-page-images {
    height: auto;
    width: 100%;
    display: grid;

    .left-section {
      height: auto;
      width: 100%;
      margin: auto;

      .text-group {
        height: 100%;
      }
    }

    .right-section {
      width: 100%;
      height: auto;
    }
  }
}

@include breakpoint-max-custom(1000) {
  .mentorship {
    &__page {
      color: var(--GDF-Neutral---Dark-Grey, #222);
      font-family: Brandon Grotesque;
      font-size: $font-size-xl !important;
      font-style: normal;
      font-weight: $font-weight-normal-medium;
      line-height: 140%;
    }

    &__text__img {
      .wp-block-media-text {
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
    }
  }

  .mentorship__text__img img {
    max-width: 100%;
    max-height: 352px;
    width: unset;
    min-width: unset;
  }

  .mentorship__video__card__wrapper {
    display: grid;
    grid-template-columns: repeat(auto-fill, 344px);
    gap: 16px;
    justify-content: center;
  }
}

@include breakpoint-max-custom(991) {
  p {
    font-size: $font-size-md;
    line-height: normal;
  }
}

@include breakpoint-max-custom(980) {
  .hero-section {
    height: auto;
    width: 100%;

    img {
      object-fit: contain;
    }

    width: 100%;
    .wp-block-cover__inner-container {
    }
  }
}

@include breakpoint-max-custom(979) {
  .hero-section {
    height: auto;
    width: 100%;

    img {
      object-fit: contain;
      margin-bottom: 40px;
      padding-bottom: 20px;
    }

    width: 100%;
    .wp-block-cover__inner-container {
    }
  }
}

@include breakpoint-max-custom(800) {
  .getinvoled__columns {
    .getinvoled__card {
      padding: 0 !important;
      padding: 1rem !important;
    }

    .wp-block-column {
      display: flex;
      justify-content: center;
    }

    justify-content: center;
    grid-template-columns: 1fr !important;
  }
}

@include breakpoint-max-custom(767) {
  .wp-block-cover.alignfull.hero-section.lg-text h4.wp-block-heading {
    font-size: 22px;
    text-align: left;
    max-width: 35%;
    margin: 0;
    padding: 0;
  }

  .media-with-left-caption-section .media-with-left-caption-images,
  .media-with-left-caption-section .media-with-right-caption-images {
    grid-template-columns: 1fr;
  }

  .media-with-left-caption-section .media-with-left-caption-images .caption-image,
  .media-with-left-caption-section .media-with-right-caption-images .caption-image {
    max-width: 100%;
    height: auto;
  }

  section.landingPage {
    &.hero-section {
      .carousel-caption {
        max-width: 30% !important;
      }
    }
    h2 {
      font-size: 22px;
    }
  }

  .donor-advised-section {
    padding: 30px 0 40px;
  }

  .season-of-giving-content-media {
    flex-direction: column;
  }

  .landing-page-images {
    height: auto;

    .left-section {
      height: auto !important;

      .text-group {
        padding: 0 20px;
      }
    }

    .right-section {
      height: auto;
    }
  }

  .left-image-text {
    grid-template-columns: 1fr;
  }

  header.wp-block-cover {
    p.has-text-align-center {
      text-align: left;
    }
  }

  .landing-page-images {
    height: auto ;
    width: 100%;
    display: grid;

    .left-section {
      height: auto;
      width: 100%;
      margin: auto;

      .text-group {
        height: 100%;
      }
    }

    .right-section {
      width: 100%;
      height: auto;
    }
  }

  .hero-section-with-two-images {
    display: grid;
    grid-template-columns: 40% 60%;
    width: 100%;
    height: auto;
    background-color: $color-red;

    .wp-block-cover__background,
    .has-background-dim {
      display: none;
    }

    .right-section {
      height: auto;

      figure {
        margin-bottom: 0;

        img {
          width: 100%;
          height: auto;
        }
      }
    }

    .left-section {
      height: auto;

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
  }

  .hero-textcenter h5 {
    margin-bottom: 20px !important;
    font-size: $font-size-md;
    padding: 20px 20px !important;
  }

  .landing-page-images {
    .text-group {
      padding: 0 20px !important;
      h2 {
        font-size: $font-size-xl !important;
      }

      p {
        font-size: $font-size-sm !important;
      }
    }
  }

  .donate__page__para {
    margin-top: 40px !important;
    padding: 0px 20px;
  }

  section.landingPage h2 {
    font-size: $font-size-md !important;
  }
}

@include breakpoint-max-custom(720) {
  .hero-textcenter-section .hero-textcenter h5 {
    font-size: $font-size-md !important;
    line-height: normal;
  }

  .mission-pillar-card-section .inner-card-container .getinvoled__card {
    min-height: auto;
    padding: 20px;
  }

  header.wp-block-cover h4 {
    max-width: 60%;
    text-align: left;
    width: 100%;
    font-size: 35px;
  }

  .donorspanel {
    flex-wrap: wrap;
  }

  .ticket-donate-btn-section {
    .button-row {
      display: grid;
      grid-template-columns: 1fr;
      max-width: 95%;
      margin: 0 auto;
      display: flex;
        justify-content: center;
    }
  }
  .marthonpanel {
    flex-wrap: wrap;
  }
}

@include breakpoint-max-custom(680) {
  .godcontainer.grantshd.godhead.godhead {
    padding: 0;
  }

  .grantspanel {
    flex-wrap: wrap;
  }

  .grantspanel img {
    max-width: 100%;
  }

  .yellowgrantblock {
    flex-wrap: wrap;
  }

  .yellowgrantblock .yellowgrant {
    width: 100%;
  }

  .yellowgrantblock .yellowgrant img {
    max-width: 100%;
  }

  .grantsblock h1 {
    font-size: $font-size-3xl;
    margin-top: 20px;
  }

  .godcontainer {
    max-width: 100%;
    padding: 20px !important;
  }

  .teamblock {
    width: 90%;
    max-width: 90%;
  }

  .teamblock figure {
    text-align: center;
  }

  p.bannerTitle {
    font-size: $font-size-2md !important;
  }

  .missionpanel {
    flex-wrap: wrap;
  }

  .missionblock {
    width: 45%;
  }

  .missionImageBlock {
    display: flex;
    flex-wrap: wrap;
  }

  .teamblock h6 {
    font-size: $font-size-lg !important;
  }

  .getinvoled__columns.two-x-two {
    grid-template-columns: auto !important;
  }
}

@include breakpoint-max-custom(580) {
  .hero-textcenter-section {
    margin: 24px 1rem;
  }
}

@include breakpoint-max-custom(480) {
  section.landingPage h2 {
    font-size: $font-size-base !important;
  }
  .missionpanel {
    flex-wrap: wrap;
  }

  .missionblock {
    width: 100%;
  }

  .ticket-donations-card {
    width: unset;
  }

  .generic-text {
    padding: 1rem !important;
  }

  .ticket-donate-btn-section {
    padding: 15px 20px;
    .wp-block-buttons {
      display: grid;
    }
  }

  .getinvoled__columns.two-x-two {
    grid-template-columns: auto;
  }
}

@include breakpoint-max-custom(420) {
  section.landingPage h2 {
    font-size: $font-size-sm !important;
  }
}

@include breakpoint-max-custom(390) {
  .landing-page-images {
    height: auto;
    width: 100%;
    display: grid;

    .left-section {
      height: 125px;
      margin: auto;

      .text-group {
        height: 100%;
      }
    }

    .right-section {
      height: auto;
    }
  }

  .hero-section {
    height: auto;
    width: 100%;
  }
}

@include breakpoint-max-custom(320) {
  .landing-page-images {
    height: auto;

    .left-section {
      height: auto;

      .text-group {
        height: 100%;
      }
    }

    .right-section {
      height: auto;
    }
  }
}
