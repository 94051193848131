@import '../../style/_variables.scss';
@import '../../style/_mixins.scss';
@import '../../style/_breakpoints.scss';

.see-all-stories {
  display: flex;
  align-items: baseline;
  padding: 35px 0;
  max-width: 1240px;
  margin: 0 auto;
  title {
    color: $gray-color;
    text-align: center;
    font-size: $font-size-xl;
    font-style: normal;
    font-weight: $font-weight-normal-medium;
    line-height: 140%;
  }

  .stories-badge-wrapper {
    display: flex;
    flex: 1;

    .single-story-badge {
      display: flex;
      height: 50px;
      min-width: 100px;
      justify-content: center;
      align-items: center;
      border-radius: 50px;
      border: 1px solid $gray-color;
      background: $color-white;
      margin: 0px 5px 0px 25px;
      padding: 0px 40px 0 29px;
      text-transform: lowercase !important;
      position: relative;
      color: $gray-color;
      text-align: center;
      font-size: $font-size-base;
      font-style: normal;
      font-weight: $font-weight-medium;
      line-height: 100%;
      cursor: pointer;
      span.close-icon {
        font-size: $font-size-xs;
        position: absolute;
        right: 4px;
        top: 9px;
        width: 20px;
        height: 20px;
        background: #f2f2f2;
        border-radius: 50%;
        border: 1px solid #ddd;
        line-height: 18px;
        cursor: pointer;
      }
    }
  }
}

@media screen and (max-width: 1366px) {
  .see-all-stories .stories-badge-wrapper .single-story-badge {
    margin: 0px 5px;
    padding: 0px 28px 0 10px;
    font-size: $font-size-sm;
    height: 40px;
  }
}
@media screen and (min-width: 1367px) {
  .see-all-stories .stories-badge-wrapper .single-story-badge span.close-icon {
    right: 8px;
    top: 14px;
  }
}

@media screen and (max-width: 991px) {
  .see-all-stories .stories-badge-wrapper .single-story-badge span.close-icon {
    top: 9px;
  }

  .see-all-stories {
    .stories-badge-wrapper {
      margin-left: 10px !important;
      gap: 10px;

      .single-story-badge {
        height: 40px;
        min-width: 100px;
        margin: 0px 0px 0px 0px;
      }
    }
  }
}

@media screen and (max-width: 640px) {
  .see-all-stories .stories-badge-wrapper {
    margin-left: 0 !important;
    gap: 10px !important;
  }
}

// @media screen and (max-width: 430px) {
//   .see-all-stories {
//     display: grid !important;
//     grid-template-columns: 1fr;
//     gap: 10px !important;
//   }
// }