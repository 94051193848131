$breakpoints-min: (
    'xs': 320,
    'sm': 390,
    'md': 768,
    'lg': 1024,
    'xl': 1440) !default;

$breakpoints-max: (
    'xs': map-get($breakpoints-min, 'sm'),
    'sm': map-get($breakpoints-min, 'md'),
    'md': map-get($breakpoints-min, 'lg'),
    'lg': map-get($breakpoints-min, 'xl'),
    'xl': map-get($breakpoints-min, 99999)) !default;

@mixin breakpoint-min($breakpoint) {
    @if map-get($breakpoints-min, $breakpoint) {
        $breakpoint_value: map-get($breakpoints-min, $breakpoint
        );

    @media (min-width: #{$breakpoint_value}px) {
        @content;
    }
}
}

@mixin breakpoint-min-1($breakpoint) {
    @if map-get($breakpoints-min, $breakpoint) {
        $breakpoint_value: map-get($breakpoints-min, $breakpoint
        ) - 1;

    @media (min-width: #{$breakpoint_value}px) {
        @content;
    }
}
}

@mixin breakpoint-max($breakpoint) {
    @if map-get($breakpoints-max, $breakpoint) {
        $breakpoint_value: map-get($breakpoints-max, $breakpoint);

        @media (max-width: #{$breakpoint_value}px) {
            @content;
        }
    }
}

@mixin breakpoint-between($min, $max) {
    $min-value: if(type-of($min)=='string', map-get($breakpoints-min, $min), $min);
    $max-value: if(type-of($max)=='string', map-get($breakpoints-min, $max), $max);

    @if $min-value and $max-value {
        @media (min-width: #{$min-value}px) and (max-width: #{$max-value}px) {
            @content;
        }
    }

    @else {
        @warn "Invalid breakpoint values: #{$min} to #{$max}.";
    }
}

@mixin breakpoint-max-custom($max-width) {
    @media (max-width: #{$max-width}px) {
      @content;
    }
}


@mixin breakpoint-custom-min-max($min, $max) {
    @if type-of($min) == "number" and type-of($max) == "number" and $min < $max {
        @media (min-width: #{$min}px) and (max-width: #{$max}px) {
            @content;
        }
    } @else {
        @warn "Invalid breakpoint values: #{$min} to #{$max}. Ensure they are numbers and that min is less than max.";
    }
}

@mixin breakpoint-custom-min($min) {
    @if type-of($min) == "number" {
        @media (min-width: #{$min}px) {
            @content;
        }
    }
}
