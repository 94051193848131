@import './_variables.scss';
@import './_mixins.scss';
@import './breakpoints.scss';

.dream-notes {
    p {
        font-size: $font-size-xl;
        font-family: Brandon Grotesque;
    }
    .dream-notes-description {
        padding: 0px;
        h3 {
            margin-bottom: 20px;
            font-family: Brandon Grotesque bold;
            line-height: 36px;
        }
        ul {
            font-family: Brandon Grotesque;
        }
    }
    .dream-notes-block {
            display: grid;
            grid-template-columns: 1fr 1fr;
            max-width: 1080px;
            gap: 20px;
            margin: 4rem auto;
            margin: 80px auto;
            grid-gap: 20px;
            display: grid;
            gap: 20px;
            grid-template-columns: 1fr 1fr;
            margin: 4rem auto;
            max-width: 1080px;
        .content-section {
            display: grid;
            grid-template-columns: 1fr 1fr;
      
          .wp-block-heading {
            font-weight: bolder;
            font-size: 1.75rem;
          }
      
          .wp-block-group {
            display: block;
          }
          .btn.btn-orange {
            &:hover {
              color: $color-orange;
              background-color: $color-orange;
              border-color: none;
            }
          }
          .wp-block-button {
            background-color: $color-orange;
            justify-content: center;
            align-items: center;
            text-align: center;
            font-family: 'Brandon Grotesque bold';
      
            a {
              color: $color-black;
              text-decoration: none;
              font-weight: $font-weight-medium;
              margin: auto;
              justify-content: center;
              align-items: center;
              padding: 0px;
            }
          }
        }
    }
}

@include breakpoint-min('xs') {
    .dream-notes {
        .dream-notes-description {
            padding: 0 2rem;
        }
        .dream-notes-block {
            margin-top: 0px;
            grid-template-columns: 1fr;
            padding: 0rem 2rem;
            .content-section {
                grid-template-columns: 1fr;
                margin-bottom: 2rem;
                img {
                    width: 100%;
                }
            }
        }
    }
    .dream-notes-description {
        padding: 0rem 2rem;
    }
}

@include breakpoint-min('md') {
    .dream-notes {
        .dream-notes-block {
            grid-template-columns: 1fr;
            padding: 0rem 2rem;
            .content-section {
                grid-template-columns: 1fr 1fr;
                margin-bottom: 0rem;
                gap: 15px;
                align-items: center;
            
                img {
                    width: 100%;
                }
            }
        }
    }
}
@include breakpoint-min('lg') {
    .dream-notes {
        .dream-notes-block {
            display: grid;
            grid-template-columns: 1fr 1fr;
            max-width: 1080px;
            gap: 20px;
            margin: 4rem auto;
            .content-section {
                display: grid;
                grid-template-columns: 1fr 1fr;
            }
        }
    }
}
@include breakpoint-min('xl') {
    .dream-notes {
        .dream-notes-block {
            display: grid;
            grid-template-columns: 1fr 1fr;
            max-width: 1080px;
            gap: 20px;
            margin: 4rem auto;
            .content-section {
                display: grid;
                grid-template-columns: 1fr 1fr;
            }
        }
    }
}
.dream-notes {
    @include breakpoint-between($breakpoint-xs, $breakpoint-sm-md) {
        .dream-notes-block{
            .content-section {
                .wp-block-heading {
                    font-size: $font-size-xl;
                    line-height: 140%;
                }
            }
        }
    }
    @include breakpoint-between($breakpoint-md, $breakpoint-md-lg) {
        .dream-notes-block {
            margin: 80px auto;
        }
        .dream-notes-description {
            padding: 0 2rem;
                margin: 40px auto 40px;
            p {
                font-size: $font-size-xl;
            }
        }
        h3.wp-block-heading {
            line-height: 36px;
            margin-bottom: 20px;
        }
        .dream-notes-block{
            .content-section {
                .wp-block-heading {
                    font-size: $font-size-xl;
                    line-height: 140%;
                }
            }
        }
    }
    @include breakpoint-between($breakpoint-lg, $breakpoint-lg-xl) {
        .dream-notes-block{
            .content-section {
                .wp-block-heading {
                    font-size: $font-size-xl;
                    line-height: 140%;
                }
            }
        }
    }
    @include breakpoint-min('xl') {
        .dream-notes-block{
            .content-section {
                .wp-block-heading {
                    font-size: $font-size-xl;
                    line-height: 140%;
                }
            }
        }
    
    }
}