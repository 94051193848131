
@import '../../../../style/_variables.scss';
@import '../../../../style/_mixins.scss';
@import '../../../../style/_breakpoints.scss';

.header-button, .header-btn {
    .btn {
        justify-content: center;
        align-items: center;
        flex-direction: column;
        font-family: 'brandon grotesque black';
        font-size: $font-size-base;
        font-style: normal;
        font-weight: $font-weight-medium;
        line-height: 16px;
        gap: 0px;
        .sub-desc {
            font-size: 10px;
        }
    }
    @include breakpoint-between($breakpoint-xs, $breakpoint-sm-md) {
        .btn {
            font-family: 'brandon grotesque black';
            font-size: $font-size-base;
            font-style: normal;
            font-weight: $font-weight-medium;
            @include breakpoint-min('xs') {
                font-size: 11.43px;
                .sub-desc {
                    font-size: 8px;
                }
            }
        }
    }
}
.header-contact-form {
    .signup-section {
        justify-content: center;
    }
}