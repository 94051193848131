@import '../../../style/_variables.scss';
@import '../../../style/_mixins.scss';
@import '../../../style/_breakpoints.scss';

.story-wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 24px;
  margin-top: 20px;
  row-gap: 40px;
  margin-bottom: 40px;

  .story {
    display: flex;
    max-width: 344px;
    flex-direction: column;
    align-items: center;
    margin-right: 0;
    justify-content: center;
    cursor: pointer;

    &__title {
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
      min-height: 111px !important;
      font-size: 25px;
      text-align: center;
      font-weight: $font-weight-medium-bold;
      margin: 1rem 0rem;
    }

    &__content {
      text-align: center;
    }

    img {
      width: 100%;
      height: fit-content;
      object-fit: cover;
      max-width: 100%;
    }

    //   .lazy-load-image-background.blur > img {
    //     opacity: 0;
    //     height: 257px;
    //     object-fit: cover;
    // }

    .lazy-load-image-loaded {
      img {
        height: 302px;
      }
    }
  }

  h3 {
    margin-top: 20px !important;
    font-size: $font-size-xl;
    line-height: normal !important;
    text-align: center;
  }
}

@media screen and (max-width: 1200px) {
  .story-wrapper {
    .story {
      justify-content: flex-start;
    }

    h3 {
      margin-top: 24px !important;
      font-size: 28px !important;
    }
  }
}

@media screen and (max-width: 767px) {
  .story-wrapper {
    grid-template-columns: 1fr 1fr;
    padding: 1rem;

    .story {
      max-width: 100%;
    }

    .story__title {
      min-height: 0px !important;
      width: 100%;
      font-size: $font-size-md;
    }

    h3 {
      margin-top: 24px !important;
      margin-bottom: 15px !important;
    }
  }
}

@media screen and (max-width: 480px) {
  .story-wrapper {
    grid-template-columns: 1fr;
    padding: 0rem;
  }
}