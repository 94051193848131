@import './_variables.scss';
@import './breakpoints.scss';
@import './mixins.scss';

.getinvoled {
    &__text {
      color: var(--GDF-Neutral---Dark-Grey, #222);
      font-family: Brandon Grotesque;
      font-size: $font-size-5xl;
      font-style: normal;
      font-weight: $font-weight-medium;
      line-height: 46px;
  
      h6 {
        color: var(--GDF-Neutral---Dark-Grey, #222);
        font-family: Brandon Grotesque;
        font-style: normal;
        font-weight: $font-weight-normal-medium;
        line-height: 140%;
        text-align: left;
      }
    }
  
    &__card {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 252px;
      min-height: 410px;
      text-align: center;
      color: $color-white;
      padding-bottom: 40px;
      padding-top: 30px;
      .wp-block-button {
        display: flex;
        justify-content: center;
      }
  
      .wp-block-button__link {
        display: flex;
        height: 48px;
        width: 126px;
        align-items: center;
        justify-content: center;
        // color: $color-black;
        color: $text-color;
        text-decoration: none !important;
        font-family: 'Brandon Grotesque';
      }
  
      h2 {
        color: $color-white !important;
        // color: var(--GDF-Neutral---White, #fff);
        text-align: center;
        font-family: Brandon Grotesque;
        font-size: $font-size-3xl !important;
        font-style: normal;
        font-weight: $font-weight-medium;
        line-height: 36px !important;
        min-height: 80px;
      }
      img {
        width: 212px !important;
        height: 180px !important;
      }
    }
  
    &__columns {
      display: flex !important;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
  
      .wp-block-column {
        padding: 0 1rem !important;
        margin: 1rem 0 !important;
      }
    }
  
    &__wrappper {
      .missionblock {
        width: 100% !important;
      }
    }
    .ticket-donations .wp-block-button.btn.btn-orange {
        width: calc(100% - 20px) !important;
    }
}

.donate {
    font-family: Brandon Grotesque;
}

.ticket-donations {
    .ticket-donations-card-section h3 {
        font-family: 'Brandon Grotesque bold';
    }
    @include breakpoint-between($breakpoint-xs, $breakpoint-xs-sm) {
        h2.wp-block-heading {
            line-height: normal !important; //Overriding the inline css
            font-size: $font-size-lg-md !important;//Overriding the inline css
            margin-bottom: 20px;
        }
    }
    @include breakpoint-between($breakpoint-sm, $breakpoint-custom-sm) {
        h2.wp-block-heading {
            line-height: normal !important; //Overriding the inline css
            font-size: $font-size-lg-md !important;//Overriding the inline css
            margin-bottom: 20px;
        }
    }
    @include breakpoint-between($breakpoint-custom-sm-md, $breakpoint-sm-md) {
        h2.wp-block-heading {
            line-height: normal !important; //Overriding the inline css
            font-size: $font-size-2md !important;//Overriding the inline css
            margin-bottom: 20px;
        }
    }
    @include breakpoint-between($breakpoint-md, $breakpoint-md-lg) {
        
        
        .ticket-donations-card-section h3 {
            font-weight: $font-weight-medium;
            margin-bottom: 20px;
        }
        .wp-block-heading {
            font-size: $font-size-2xl !important; //Overriding the inline css
        }
        .wp-block-column {
            p {
                margin-bottom: 1rem;
                margin-top: 0;
                line-height: normal;
                font-size: $font-size-xl;
            }
        }
    }
    @include breakpoint-between($breakpoint-lg, $breakpoint-lg-xl) {
        h2.wp-block-heading {
            font-size: $font-size-5xl !important; //overriding the font-size
            font-weight: $font-weight-medium;
            line-height: 46px !important; //overriding the font-size
            margin-bottom: 20px;
        }
    }
    @include breakpoint-min('xl') {
        h2.wp-block-heading {
            font-size: $font-size-5xl !important; //overriding the font-size
            font-weight: $font-weight-medium;
            line-height: 46px !important; //overriding the font-size
            margin-bottom: 20px;
        }
    }
    .wp-block-button.btn.btn-orange {
        font-family: Brandon Grotesque bold;
        padding: 0;
        @include breakpoint-max('sm') {            
            font-size: 16px;
        }
        strong {
            font-weight: unset;
        }
    }
    h2 {
        font-size: $font-size-lg-md !important;
        line-height: 31px !important;
        @include breakpoint-min('lg') {
            font-size: $font-size-5xl !important;
            line-height: 46px !important;
        }
        @include breakpoint-min('md') {
            font-size: $font-size-2xl !important;
        }
    }
    .wp-block-buttons.btn.btn-orange {
        font-family: Brandon Grotesque bold;
    }
}

.corporate-partners {
    .sponsorship-section {
        font-family: Brandon Grotesque;
    }
    .community__services {
        h2 {
            font-size: $font-size-2md;
        }
    }
}

.nyc-marathon {
    .season-of-giving-content-img img {
        height: auto;
        width: 100%;
        margin: 40px 0px;
    }
}

.fundraising {
    font-family: Brandon Grotesque;
    .hero-section h1 {
        font-family: "Brandon Grotesque bold" !important;
        font-weight: 450 !important;
        @include breakpoint-min('xs'){
            font-size: 16px !important;
        }
        @include breakpoint-min('sm'){
            font-size: 20px !important;
        }
        @include breakpoint-min('md') {
            font-size: 32px !important;
            margin-left: 30px !important;
        }
        @include breakpoint-min('lg') {
            margin-left: 40px !important;
        }
        @include breakpoint-min('xl') {
            font-size: 42px !important;
            margin-left: 40px !important;
        }
    }
}