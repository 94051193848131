@import './_variables.scss';
@import './_mixins.scss';
@import './breakpoints.scss';


.blog {
    figure figcaption {
        font-family: 'Brandon Grotesque';
    }
    .blog-wrapper {
        .item-title {
            color: $text-color;
            font-size: $font-size-5xl;
            font-weight: $font-weight-medium;
            line-height: 46px;
            @include breakpoint-max('sm') {
                font-size: 22px;
            }
        }
    }
}
.blog-details-container .blog-details {
    .blog-story-heading {
        font-size: $font-size-5xl;
        @include breakpoint-max('sm') {
            font-size: 22px;
            line-height: normal;
        }
    }
}
.see-all-stories {
    .left-section {
        font-family: 'Brandon Grotesque';
    }
}