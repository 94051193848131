@import '../../style/_variables.scss';
@import '../../style/_mixins.scss';
@import '../../style/_breakpoints.scss';

/* Base Styling for Breadcrumb */
.breadcrumb-container {
    min-height: 73px;
    width: 100%;
    padding: 24px 16px;
    display: flex;
    flex-wrap: wrap;
    justify-content: start;
    align-items: center;
    list-style: none;
    --bs-item-padding-x: 0.5rem;
    height: auto;
    
  
    .link {
        font-family: 'Brandon Grotesque';
        font-size: $font-size-md;
        font-weight: $font-weight-normal-medium;
        line-height: 140%;
        color: $color-blue;
        text-decoration: none;
        :hover {
            text-decoration: underline;
        }
    }
    ul {
        list-style: none;
        padding: 0;
        margin: 0;
        display: flex;
        flex-wrap: wrap;
    }
    .separator {
        margin: 0 8px;
        @include common-height-width-for-separator;
    }
    .item {
        display: flex;
        align-items: center;
        span {
            @include typography($font-size-md, $font-weight-normal-medium, Brandon Grotesque, 140%);
            color: $gray-color;
        }
    }
    
    .link:hover {
        text-decoration: underline;
    }
    
    span.separator {
        margin: 0 8px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: $font-size-xs;
        line-height: 18px;
        font-weight: $font-weight-light-medium;
    }
    @include breakpoint-between($breakpoint-xs, $breakpoint-xs-sm) {
        padding: 16px;
        span.separator {
            margin: 0 6px;
            @include common-height-width-for-separator;
        }
    }
    @include breakpoint-between($breakpoint-sm, $breakpoint-sm-md) {
        padding: 16px;
        span.separator {
            margin: 0 6px;
            @include common-height-width-for-separator;
        }
    }
    @include breakpoint-between($breakpoint-md, $breakpoint-md-lg) {
        padding: 16px;
        span.separator {
            margin: 0 6px;
            @include common-height-width-for-separator;
        }
    }
    @include breakpoint-between($breakpoint-lg, $breakpoint-lg-xl) {
        padding: 16px;
        span.separator {
            margin: 0 10px;
            @include common-height-width-for-separator;
        }
    }
    @include breakpoint-min('xl') {
        padding: 24px 80px;
        span.separator {
            margin: 0 10px;
            @include common-height-width-for-separator;
        }
    }
}
