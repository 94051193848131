@import './_variables.scss';
@import './_breakpoints.scss';
.carousel-section {
    .owl-carousel {
        .owl-nav {
            top: 25.6%;
            margin-top: 0px;
        }
        @include breakpoint-between(320, 389) {
            .owl-nav {
                top: 24.4%;
                margin-top: 0px;
            }
        }
        @include breakpoint-max('lg') {
            .owl-nav {
                top: 22.4%;
                margin-top: 0px;
            }
        }
    }
}