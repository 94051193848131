@import './style/_variables.scss';

.wp-block-cover__inner-container {
  max-width: 1200px;
  // max-width: unset !important;
  width: 100%;
  text-align: left;
  justify-content: flex-start;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.wp-block-cover h4 {
  color: $color-white;
  font-weight: $font-weight-medium-bold;
  font-family: Brandon Grotesque;
  text-align: center;
  width: 34%;
}
.category {
  .wp-block-cover h4 {
    color: $color-white;
    font-weight: $font-weight-normal-bold;
    font-family: Brandon Grotesque bold;
    text-align: center;
    width: 34%;
  }
}

.scholar-testimonials {
  text-align: center;
  padding: 40px 0;
  .hero-textcenter-section {
    &.hero-textcenter p {
      padding: 15px 0;
    }
  }
}

.light-pink-bg {
  background: $light-pink-color;
  padding: 10px 0rem !important;
}
